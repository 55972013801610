import React, { FC, useState } from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// import fonts
import { SourceObject } from "@react-pdf/types";
import workSansBold from "./fontsPDF/WorkSans-Bold.ttf";
import workSansExtraLight from "./fontsPDF/WorkSans-ExtraLight.ttf";
import workSansSemiBold from "./fontsPDF/WorkSans-SemiBold.ttf";
import workSansLight from "./fontsPDF/WorkSans-Light.ttf";
import workSansMedium from "./fontsPDF/WorkSans-Medium.ttf";
import workSansRegular from "./fontsPDF/WorkSans-Regular.ttf";

// import images
import ecowaarde from "../../../../../imgs/pdfReport/ecowaarde.png";
import altum from "../../../../../assets/images/altumai-svg.svg";
import kadaster from "../../../../../imgs/pdfReport/kadaster.png";
import cb from "../../../../../imgs/pdfReport/cb.png";
import RON from "../../../../../imgs/pdfReport/RON.png";
import header from "../../../../../imgs/pdfReport/header.png";
import ecolabelGreen from "../../../../../imgs/pdfReport/ecolabel-green.png";
import ecolabelRed from "../../../../../imgs/pdfReport/ecolabel-red.png";
import home from "../../../../../imgs/pdfReport/home.png";
import homeBorder from "../../../../../imgs/pdfReport/home-border.png";
import arrow from "../../../../../imgs/pdfReport/arrow.png";
import houseIcon from "../../../../../imgs/pdfReport/house.png";
import value from "../../../../../imgs/pdfReport/value.png";
import valueIcon from "../../../../../imgs/pdfReport/valueIcon.png";
import tree from "../../../../../imgs/pdfReport/tree.png";
import plant from "../../../../../imgs/pdfReport/plant.png";
import fireIcon from "../../../../../imgs/pdfReport/fire.png";
import socketIcon from "../../../../../imgs/pdfReport/socket.png";
import { Colors } from "../../../../../styles/Theme";

// Register fonts for document
Font.register({
  family: "WorkSans",
  format: "truetype",
  // fontStyle: 'normal',
  fonts: [
    {
      src: workSansExtraLight,
      fontWeight: 100,
    },
    {
      src: workSansLight,
      fontWeight: 200,
    },
    {
      src: workSansRegular,
      fontWeight: 300,
    },
    {
      src: workSansMedium,
      fontWeight: 500,
    },
    {
      src: workSansSemiBold,
      fontWeight: 600,
    },
    {
      src: workSansBold,
      fontWeight: 700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  ecowaardeIcon: {
    position: "absolute",
    width: 120,
    height: 80,
    top: 32,
    left: 6,
  },
  mainPage: {
    padding: 32,
    flexDirection: "column",
    // justifyContent: 'center',
    color: "#2B2D34",
  },
  page: {
    padding: 48,
    paddingTop: 0,
    color: "#2B2D34",
  },
  titleContainer: {
    height: 500,
    position: "relative",
    justifyContent: "flex-end",
    flexDirection: "column",
    paddingBottom: 160,
    marginLeft: 50,
    // width: 380
  },
  title: {
    fontSize: 20,
    textAlign: "left",
    fontFamily: "WorkSans",
    fontWeight: 700,
    color: "white",
    marginLeft: 25,
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 14,
    fontFamily: "WorkSans",
    fontWeight: 300,
    color: "white",
    marginLeft: 25,
    width: 400,
  },
  homeImage: {
    position: "absolute",
    height: 480,
    // top: -360,
    // left: 70
  },
  secondTitle: {
    color: "#32aed7",
    fontSize: 10,
    fontFamily: "WorkSans",
    fontWeight: 600,
  },
  content: {
    position: "absolute",
    bottom: 35,
    left: 35,
    width: 520,
  },
  link: {
    alignSelf: "flex-end",
    color: "#32aed7",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  heading: {
    fontSize: 18,
    marginBottom: 40,
    marginTop: 40,
    color: "#32aed7",
    letterSpacing: "0.4pt",
  },
  question: {
    marginTop: 32,
    marginBottom: 8,
    fontSize: 14,
    fontFamily: "WorkSans",
    fontWeight: 500,
  },
  text: {
    fontSize: 14,
    lineHeight: "1.3pt",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  logoContainer: {
    position: "absolute",
    bottom: 48,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    left: 50,
    width: 500,
  },
  logo: {
    height: 24,
  },
  headerImage: {
    position: "absolute",
    left: -15,
    top: 28,
    height: 45,
  },
  headerContainer: {
    position: "relative",
  },

  table: {
    width: 500,
    border: 1,
    borderColor: "#42ba7a",
    marginVertical: 20,
  },
  tableHeader: {
    backgroundColor: "#42ba7a",
    color: "white",
    padding: 6,
    paddingLeft: 10,
    fontSize: 11,
    fontFamily: "WorkSans",
    fontWeight: 600,
  },
  innerTable: {
    flexDirection: "row",
    alignItems: "center",
    padding: 4,
    paddingLeft: 10,
    fontSize: 12,
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  tableRowHeader: {
    // width: 150,
    fontSize: 12,
    fontFamily: "WorkSans",
    fontWeight: 500,
  },
  innerTable_2: {
    flexDirection: "column",
    paddingLeft: 0,
    padding: 0,
  },
  tableRowContent: {
    width: "100%",
    borderBottom: 1,
    borderBottomColor: "#42ba7a",
    paddingVertical: 10,
    marginLeft: 0,
    paddingLeft: 0,
    textAlign: "center",
  },
  energyLabel: {
    flexDirection: "row",

    // justifyContent: 'space-between',
    alignItems: "center",
    fontSize: 16,
    fontFamily: "WorkSans",
    fontWeight: 200,
    marginBottom: 14,
    marginLeft: 20,
    marginRight: 20,
  },
  energyLabel_2: {
    width: 200,
    backgroundColor: "#F1F5F7",
    fontSize: 10,
    flexDirection: "row-reverse",
    padding: 10,
    borderRadius: 10,
    margin: 0,
    justifyContent: "space-between",
  },
  ecoLabelImage: {
    position: "absolute",
    height: 150,
    width: 130,
    left: -23,
    top: -10,
  },
  ecoLabelImage_2: {
    position: "absolute",
    height: 60,
    width: 50,
    top: -10,
    left: -8,
  },
  energyHeading: {
    fontSize: 26,
    fontFamily: "WorkSans",
    fontWeight: 600,
    marginBottom: 14,
  },
  energyItemIcon: {
    marginRight: 5,
    width: 30,
  },
  energyItem: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 12,
    marginTop: 12,
  },
  energyItemTitle: {
    fontSize: 10,
    fontFamily: "WorkSans",
    fontWeight: 600,
    marginBottom: 8,
  },
  energyItem_2: {
    marginTop: 6,
    fontSize: 10,
  },
  energyItemTitle_2: {
    marginBottom: 4,
    fontSize: 8,
  },
  energyLabelLetter: {
    fontSize: 105,
    fontFamily: "WorkSans",
    fontWeight: 600,
    color: "#fff",
    marginLeft: 140,
    marginTop: 20,
  },
  world: {
    width: 260,
    marginLeft: 60,
    padding: 40,
    backgroundColor: "#F1F5F7",
    borderRadius: 10,
    fontSize: 18,
    lineHeight: "1.3pt",
  },

  copyright: {
    position: "absolute",
    bottom: 10,
    fontSize: 10,
    width: 600,
    textAlign: "center",
    fontFamily: "WorkSans",
    fontWeight: 200,
  },
  btn: {
    margin: "1rem 0",
    padding: "0.7rem",
    borderRadius: "24px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "12px",
    transition: "transform 0.3s ease",
    maxWidth: "230px",
    width: "220px",
    cursor: "pointer",
    color: "white",
    textDecoration: "none",
    backgroundColor: Colors.main.green,
  },
});

interface Props {
  data: any;
  buildingPhoto: SourceObject;
}

// Create Document Component
const DownloadReport: FC<Props> = (props) => {
  const [map, setMap] = useState("");
  // const [buildingPhoto, setBuildingPhoto] = useState('');
  const { data } = props;

  // Get Map Image from Google Maps Static api
  const getMap = (lon: any, lat: any) => {
    fetch(
      `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCx4vspKDUEOBO6QxTw4K_R5JKbhEfKHhs&center=${lat},${lon}&markers=color:red%7Clabel:S%7C${lat},${lon}&zoom=14&size=800x200&language=nl`,
    ).then(
      (result) => {
        setMap(result.url);
      },
      (error) => {
        console.log(error);
      },
    );
  };
  getMap(data.address.location.lon, data.address.location.lat);

  // Prepare new measures array that have differences in before and after, and add to eact item name in Dutch
  const prepareChangedMeasures = preparedChangedMeasuresFunc(data);
  const featuredMeasures = prepareChangedMeasures();

  function Report() {
    return (
      <Document>
        {/* 1 Page start Cover page */}
        <Page size="A4" style={styles.mainPage}>
          <Image src={ecowaarde} style={styles.ecowaardeIcon} />
          <View style={styles.titleContainer}>
            <Image src={homeBorder} style={[styles.homeImage, { top: 40 }]} />
            <Image src={home} style={styles.homeImage} />

            <Text style={styles.title}>Verduurzamingsrapport</Text>
            <Text style={styles.subTitle}>
              Het verduurzamingsadvies om uw energielabel te verbeteren en
              maandelijkse lasten te verlagen.
            </Text>
          </View>

          <View style={styles.content}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Image
                src={header}
                style={{
                  position: "absolute",
                  height: 100,
                  width: 90,
                  left: -10,
                }}
              />
              <Image
                src={houseIcon}
                style={{ height: 60, width: 60, marginLeft: 7 }}
              />
              <View>
                <Text style={styles.secondTitle}>
                  VERDUURZAMINGSRAPPORT VOOR
                </Text>
                <Text
                  style={{
                    marginVertical: 4,
                    fontSize: 16,
                    fontFamily: "WorkSans",
                    fontWeight: 600,
                  }}
                >
                  {data.address.street.toUpperCase()}{" "}
                  {data.address.house_number}
                </Text>
                <Text style={styles.secondTitle}>
                  {data.address.city.toUpperCase()}
                </Text>
              </View>
            </View>

            {/* <Text style={styles.link}>ECOWAARDE.NL</Text> */}
          </View>
        </Page>
        {/* 1 Page end */}

        {/* 2 Page  start Het Ecowaarderapport */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Verduurzamingsrapport</Text>
          </View>

          <Text style={styles.text}>
            Met dit rapport helpen we u graag op weg bij het verduurzamen van uw
            woning door te tonen welke voordelen dat voor u als woningeigenaar
            kan hebben.
          </Text>

          <Text style={styles.question}>Wat is de Ecowaarde?</Text>
          <Text style={styles.text}>
            Het verduurzamen van een woning levert niet alleen een lagere
            energierekening of extra comfort op. Het zorgt er ook voor dat de
            waarde van een woning stijgt. Dit noemen wij de Ecowaarde. De
            Ecowaarde is de extra marktwaarde van een woning die ontstaat
            doordat het energielabel stijgt wanneer een woning wordt
            verduurzaamd.
          </Text>

          <Text style={styles.question}>Hoe komt de Ecowaarde tot stand?</Text>
          <Text style={styles.text}>
            De Ecowaarde wordt berekend door de waarde van een woning vòòr en na
            de stijging van het energielabel met elkaar te vergelijken. Hierbij
            wordt gebruik gemaakt van de energiebesparingsverkenner van de
            Rijksdienst voor Ondernemend Nederland, het automatische
            waarderingsmodel voor onroerend goed van Altum AI en openbare
            informatie over de woning afkomstig van het CBS, en het Kadaster.
          </Text>

          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Voorwaarden</Text>
          </View>

          <Text style={styles.text}>
            De uitkomsten van dit rapport moeten als indicatief worden
            beschouwd. Altum AI spant zich naar beste vermogen in om informatie
            te verstrekken die juist is, maar kan niet in staan voor de
            juistheid van informatie verkregen van derden. Altum AI is niet
            aansprakelijk voor enige schade (direct of indirect) die voortvloeit
            uit het gebruik van de gegevens in het Verduurzamingsrapport.
          </Text>

          {/* Row of logos */}
          <View style={styles.logoContainer}>
            <Image src={altum} style={styles.logo} />
            <Image src={kadaster} style={styles.logo} />
            <Image src={cb} style={styles.logo} />
            <Image src={RON} style={styles.logo} />
          </View>

          {/* Copyright */}
          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 2 Page end */}

        {/* 3 Page start Basisgegevens, building and map images needed */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Basisgegevens</Text>
          </View>
          {/* Basic data table */}
          <View style={[styles.table, { width: 350 }]}>
            <Text style={styles.tableHeader}>Gegevens woning</Text>
            <View>
              <View style={[styles.innerTable, { marginTop: 10 }]}>
                <Text style={[styles.tableRowHeader, { width: 150 }]}>
                  Volledig adres:
                </Text>
                <View>
                  <Text>
                    {data.address.street} {data.address.house_number}
                  </Text>
                  <Text>{data.address.post_code}</Text>
                  <Text>{data.address.city}</Text>
                </View>
              </View>
              <View style={styles.innerTable}>
                <Text style={[styles.tableRowHeader, { width: 150 }]}>
                  Bouwjaar:
                </Text>
                <Text>{data.building.build_year}</Text>
              </View>
              <View style={styles.innerTable}>
                <Text style={[styles.tableRowHeader, { width: 150 }]}>
                  Oppervlakte:
                </Text>
                <Text>{data.building.inner_surface} vierkante meter</Text>
              </View>
              <View style={[styles.innerTable, { marginBottom: 10 }]}>
                <Text style={[styles.tableRowHeader, { width: 150 }]}>
                  Type woning:
                </Text>
                <Text>{data.building.type}</Text>
              </View>
            </View>
          </View>

          {/* Building image */}
          <View style={styles.table}>
            <Text style={styles.tableHeader}>Afbeelding woning</Text>
            <Image src={props.buildingPhoto} />
          </View>

          {/* Map Image */}
          <View style={styles.table}>
            <Text style={styles.tableHeader}>Locatie woning:</Text>
            <Image src={map} style={{ height: 150, width: 500 }} />
          </View>

          {/* Copyright */}
          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 3 Page end */}

        {/* 4 Page start Huidige situatie */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Huidige situatie</Text>
          </View>
          {/* Current energy info, icons and details about info needed  */}
          <View style={styles.energyLabel}>
            <View>
              <Text style={styles.energyHeading}>Energielabel</Text>
              <Text>Geschat energieverbruik:</Text>
              <View style={styles.energyItem}>
                <Image style={styles.energyItemIcon} src={socketIcon} />
                <View>
                  <Text style={styles.energyItemTitle}>
                    Elektriciteit per jaar:
                  </Text>
                  <Text>{data.usage.energy} kWh</Text>
                </View>
              </View>
              <View style={styles.energyItem}>
                <Image style={styles.energyItemIcon} src={fireIcon} />
                <View>
                  <Text style={styles.energyItemTitle}>Gas per jaar:</Text>
                  <Text>{data.usage.gas} m³</Text>
                </View>
              </View>
              <View />
            </View>

            {/* Place for energy label */}
            <View style={[styles.energyLabelLetter, { position: "relative" }]}>
              <Image src={ecolabelRed} style={styles.ecoLabelImage} />
              <Text>{data.label.current}</Text>
            </View>
          </View>

          {/* place for measures table */}

          <View style={{ flexDirection: "row" }}>
            <View style={[styles.table, { width: 250, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Maatregelen
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Dakisolatie
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Spouwmuurisolatie
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Zonnepanelen
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Vloerisolatie
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Installatie
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Ramen woonkamer
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Ramen slaapkamer
                </Text>
                <Text
                  style={[
                    styles.tableRowHeader,
                    styles.tableRowContent,
                    { borderBottom: 0 },
                  ]}
                >
                  Douche warmteterugwinning
                </Text>
              </View>
            </View>
            <View style={[styles.table, { width: 250 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Geschat aanwezig
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text style={styles.tableRowContent}>
                  {data.measures.roof_insulation.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.wall_insulation.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.solar_panels.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.floor_insulation.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.instalation.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.living_room_windows.before.desc}
                </Text>
                <Text style={styles.tableRowContent}>
                  {data.measures.bedroom_windows.before.desc}
                </Text>
                <Text style={[styles.tableRowContent, { borderBottom: 0 }]}>
                  {data.measures.shower.before.desc}
                </Text>
              </View>
            </View>
          </View>

          {/* Copyright */}
          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 4 Page end */}

        {/* 5 Page start Potentiële situatie */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Potentiële situatie</Text>
          </View>
          {/* Potential energy info, icons and details about info needed  */}
          <View style={styles.energyLabel}>
            <View>
              <Text style={styles.energyHeading}>Energielabel</Text>
              <Text>Geschat energieverbruik:</Text>
              <View style={styles.energyItem}>
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                  <Text style={styles.energyItemTitle}>
                    Electriciteitsverbruik:
                  </Text>
                  <Text style={{ marginLeft: 3 }}>
                    {data.usage_potential.energy}
                    {""}kWh
                  </Text>
                </View>
              </View>
              <View style={styles.energyItem}>
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                  <Text style={styles.energyItemTitle}>Gasverbruik:</Text>
                  <Text style={{ marginLeft: 3 }}>
                    {data.usage_potential.gas}
                  </Text>
                </View>
              </View>
              <View style={styles.energyItem}>
                <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                  <Text style={styles.energyItemTitle}>CO2:</Text>
                  <Text style={{ marginLeft: 3 }}>{data.CO2.potential} kg</Text>
                </View>
              </View>
            </View>

            {/* Place for enery label, figure needed */}
            <View style={[styles.energyLabelLetter, { position: "relative" }]}>
              <Image src={ecolabelGreen} style={styles.ecoLabelImage} />
              <Text>{data.label.potential}</Text>
            </View>
          </View>

          {/* place for measures and savings table */}
          <View style={{ flexDirection: "row", position: "relative" }}>
            <View style={[styles.table, { width: 140, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Maatregelen
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                    {measure[1].nameInDutch}
                  </Text>
                ))}
              </View>
            </View>
            <View style={[styles.table, { width: 120, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Doel
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={styles.tableRowContent}>
                    {measure[1].after.desc}
                  </Text>
                ))}
              </View>
            </View>
            <View style={[styles.table, { width: 85, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Investering
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text
                  style={[
                    styles.tableRowContent,
                    { paddingVertical: "50%", borderBottom: 0 },
                  ]}
                >
                  €{data.financial.total_investment.toLocaleString("de-DE")}
                  ,-
                </Text>
              </View>
            </View>
            <View style={[styles.table, { width: 80, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Besparing
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text
                  style={[
                    styles.tableRowContent,
                    { paddingVertical: "50%", borderBottom: 0 },
                  ]}
                >
                  €{data.financial.savings_monthly.toLocaleString("de-DE")}
                  ,-
                </Text>
              </View>
            </View>
            <View style={[styles.table, { width: 85 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Rendement
              </Text>
              <View
                style={[
                  styles.innerTable,
                  styles.innerTable_2,
                  { alignItems: "center" },
                ]}
              >
                <Text
                  style={[
                    styles.tableRowContent,
                    { paddingVertical: "50%", borderBottom: 0 },
                  ]}
                >
                  {Math.floor(
                    ((data.financial.savings_monthly * 12) /
                      data.financial.total_investment) *
                      100,
                  )}
                  %
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 5 Page end */}

        {/* 6 Page start Complete overzicht */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Individuele maatregelen</Text>
          </View>
          <View style={{ flexDirection: "row", position: "relative" }}>
            <View style={[styles.table, { width: 140, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Maatregelen
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                    {measure[1].nameInDutch}
                  </Text>
                ))}
              </View>
            </View>
            <View style={[styles.table, { width: 120, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Investering
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={styles.tableRowContent}>
                    € {measure[1].investment.toLocaleString("de-DE")}
                  </Text>
                ))}
              </View>
            </View>
            <View style={[styles.table, { width: 85, borderRight: 0 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Besparing
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={styles.tableRowContent}>
                    {measure[1].saving}
                  </Text>
                ))}
              </View>
            </View>
            <View style={[styles.table, { width: 140 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Voor/na situatie
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                {featuredMeasures.map((measure) => (
                  <Text style={styles.tableRowContent}>
                    {measure[1].after.desc}
                  </Text>
                ))}
              </View>
            </View>
          </View>

          {/* Copyright */}
          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 6 Page end */}
        {/* Page 7 start */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Complete overzicht</Text>
          </View>

          {/* Totale waardestijging */}
          <View style={{ flexDirection: "row", marginVertical: 20 }}>
            <View
              style={{
                backgroundColor: "#F1F5F7",
                padding: 15,
                paddingRight: 20,
                paddingLeft: 20,
                borderRadius: 10,
                width: 400,
              }}
            >
              <Text style={styles.energyHeading}>Totale waardestijging</Text>
              <Text style={[styles.text, { color: "#32aed7" }]}>
                €{data.financial.eco_value.toLocaleString("de-DE")}
                ,-
              </Text>
            </View>
            <View style={{ position: "relative", left: -60, top: -35 }}>
              <Image
                src={value}
                style={{
                  height: 170,
                  width: 170,
                  position: "absolute",
                  top: 0,
                }}
              />
              <Image
                src={valueIcon}
                style={{
                  height: 100,
                  width: 100,
                  position: "absolute",
                  top: 35,
                  left: 29,
                }}
              />
              <Text
                style={{
                  position: "absolute",
                  fontSize: 40,
                  top: 63,
                  left: 57,
                  color: "white",
                }}
              >
                €
              </Text>
            </View>
          </View>

          {/* Situation comparison */}
          <View
            style={{ flexDirection: "column", marginTop: 60, marginBottom: 20 }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text
                style={[
                  styles.tableRowHeader,
                  { width: 200, textAlign: "center" },
                ]}
              >
                Huidige situatie
              </Text>
              <Text
                style={[
                  styles.tableRowHeader,
                  { width: 200, textAlign: "center" },
                ]}
              >
                Potentiële situatie
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              {/* Current energy info, icons and details about info needed  */}
              <View style={[styles.energyLabel, styles.energyLabel_2]}>
                <View>
                  <Text>Geschat energieverbruik:</Text>
                  <View style={[styles.energyItem, styles.energyItem_2]}>
                    <Image style={styles.energyItemIcon} src={socketIcon} />
                    <View>
                      <Text
                        style={[
                          styles.energyItemTitle,
                          styles.energyItemTitle_2,
                        ]}
                      >
                        Elektriciteit per jaar:
                      </Text>
                      <Text>{data.usage.energy} kWh</Text>
                    </View>
                  </View>
                  <View style={[styles.energyItem, styles.energyItem_2]}>
                    <Image style={styles.energyItemIcon} src={fireIcon} />
                    <View>
                      <Text
                        style={[
                          styles.energyItemTitle,
                          styles.energyItemTitle_2,
                        ]}
                      >
                        Gas per jaar:
                      </Text>
                      <Text>{data.usage.gas} m³</Text>
                    </View>
                  </View>
                  <View />
                </View>

                {/* Place for energy label, figure needed */}
                <View
                  style={[
                    styles.energyLabelLetter,
                    {
                      marginLeft: 10,
                      marginBottom: 10,
                      fontSize: 40,
                    },
                  ]}
                >
                  <Image src={ecolabelRed} style={styles.ecoLabelImage_2} />
                  <Text>{data.label.current}</Text>
                </View>
              </View>

              <Image src={arrow} style={{ width: 30 }} />

              {/* Potential energy info, icons and details about info needed  */}
              <View style={[styles.energyLabel, styles.energyLabel_2]}>
                <View>
                  <Text>Geschat energieverbruik:</Text>
                  <View style={styles.energyItem}>
                    <View>
                      <Text
                        style={[
                          styles.energyItemTitle,
                          styles.energyItemTitle_2,
                        ]}
                      >
                        Electriciteitsverbruik:
                      </Text>
                      <Text>{data.usage_potential.energy} kWh</Text>
                    </View>
                  </View>
                  <View style={styles.energyItem}>
                    <View>
                      <Text
                        style={[
                          styles.energyItemTitle,
                          styles.energyItemTitle_2,
                        ]}
                      >
                        Gasverbruik:
                      </Text>
                      <Text>{data.usage_potential.gas}</Text>
                    </View>
                  </View>
                  <View style={[styles.energyItem, styles.energyItem_2]}>
                    <View
                      style={{ flexDirection: "row", alignItems: "baseline" }}
                    >
                      <Text
                        style={[
                          styles.energyItemTitle,
                          styles.energyItemTitle_2,
                        ]}
                      >
                        CO2:
                      </Text>
                      <Text style={{ marginLeft: 3 }}>
                        {data.CO2.potential} kg
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Place for enery label, figure needed */}
                <View
                  style={[
                    styles.energyLabelLetter,
                    {
                      marginLeft: 10,
                      marginBottom: 10,
                      fontSize: 40,
                      position: "relative",
                    },
                  ]}
                >
                  <Image src={ecolabelGreen} style={styles.ecoLabelImage_2} />
                  <Text>{data.label.potential}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Total info table */}
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.table,
                {
                  width: 220,
                  borderRight: 0,
                  borderTop: 0,
                  borderLeft: 0,
                },
              ]}
            >
              <Text
                style={[
                  styles.tableHeader,
                  {
                    textAlign: "center",
                    backgroundColor: "white",
                    borderColor: "white",
                  },
                ]}
              >
                {" "}
                header{" "}
              </Text>
              <View
                style={[
                  styles.innerTable,
                  styles.innerTable_2,
                  { borderLeft: 1, borderTop: 1, borderColor: "#4DC38D" },
                ]}
              >
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Verduurzamingsmaatregelen
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  Waardestijging woning
                </Text>
                <Text style={[styles.tableRowHeader, styles.tableRowContent]}>
                  {" "}
                </Text>
                <Text
                  style={[
                    styles.tableRowHeader,
                    styles.tableRowContent,
                    {
                      backgroundColor: "#F1F5F7",
                      borderBottomColor: "#F1F5F7",
                    },
                  ]}
                >
                  Totaal na 1 jaar
                </Text>
              </View>
            </View>
            <View style={[styles.table, { width: 140, borderRight: 0 }]}>
              <Text
                style={[
                  styles.tableHeader,
                  {
                    textAlign: "center",
                    backgroundColor: "#FF2F17",
                    borderColor: "#FF2F17",
                  },
                ]}
              >
                Totaal investering
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text style={styles.tableRowContent}>
                  €{data.financial.total_investment.toLocaleString("de-DE")}
                  ,-
                </Text>
                <Text style={styles.tableRowContent}> </Text>
                <Text style={styles.tableRowContent}> </Text>
                <Text
                  style={[
                    styles.tableRowContent,
                    {
                      backgroundColor: "#F1F5F7",
                      borderBottomColor: "#F1F5F7",
                    },
                  ]}
                >
                  €{data.financial.total_investment.toLocaleString("de-DE")}
                  ,-
                </Text>
              </View>
            </View>
            <View style={[styles.table, { width: 140 }]}>
              <Text style={[styles.tableHeader, { textAlign: "center" }]}>
                Totaal rendement
              </Text>
              <View style={[styles.innerTable, styles.innerTable_2]}>
                <Text style={styles.tableRowContent}>
                  €
                  {(data.financial.savings_monthly * 12).toLocaleString(
                    "de-DE",
                  )}
                  ,-
                </Text>
                <Text style={styles.tableRowContent}>
                  €{data.financial.eco_value.toLocaleString("de-DE")}
                  ,-
                </Text>
                <Text style={styles.tableRowContent}> </Text>
                <Text
                  style={[
                    styles.tableRowContent,
                    {
                      backgroundColor: "#F1F5F7",
                      borderBottomColor: "#F1F5F7",
                    },
                  ]}
                >
                  €
                  {(
                    data.financial.total_saving + data.financial.eco_value
                  ).toLocaleString("de-DE")}
                  ,-
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>
        {/* 7 Page end */}

        {/* Page 8 start */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headerContainer}>
            <Image src={header} style={styles.headerImage} />
            <Text style={styles.heading}>Rendement voor de wereld</Text>
          </View>
          <View style={{ flexDirection: "column", padding: 20 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image src={tree} style={{ width: 120 }} />
              <Text style={styles.world}>
                In 1 jaar{" "}
                {Math.floor((data.CO2.current - data.CO2.potential) / 5)}{" "}
                vierkante meter bos gered van ontbossing.
              </Text>
            </View>

            <View
              style={{
                position: "relative",
                marginVertical: 50,
                marginLeft: 45,
              }}
            >
              <Image
                src={header}
                style={{
                  height: 45,
                  transform: "rotate(-90deg)",
                  position: "absolute",
                  top: -10,
                  left: -12,
                }}
              />
              <Text
                style={{
                  fontFamily: "WorkSans",
                  fontWeight: 300,
                  color: "#32aed7",
                }}
              >
                of
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Image src={plant} style={{ width: 120 }} />
              <Text style={styles.world}>
                In 1 jaar{" "}
                {Math.floor((data.CO2.current - data.CO2.potential) / 200)}{" "}
                nieuwe bomen die kunnen groeien voor 10 jaar.
              </Text>
            </View>
          </View>
          <Text style={styles.copyright}>
            {`PRODUCT VAN ALTUM AI | COPYRIGHT ${new Date().getFullYear()} | ALTUM.AI`}
          </Text>
        </Page>

        {/* Page 8 end */}
      </Document>
    );
  }
  if (map) {
    return (
      <PDFDownloadLink
        document={<Report />}
        fileName={`Verduurzamings_rapport-${data.address.post_code}-${
          data.address.house_number
        }${data.address.addition ? `-${data.address.addition}` : ""}.pdf`}
        style={styles.btn}
      >
        Download PDF
      </PDFDownloadLink>
    );
  }
  return null;
};
export default React.memo(DownloadReport);
function preparedChangedMeasuresFunc(data: any) {
  return () => {
    // create array of measures and add name in dutch to each measure
    const measuresArray = Object.entries(data.measures);

    const featuredMeasures = featuredMeasuresFunc(measuresArray);

    return featuredMeasures.filter(
      (measure) => measure[1].before.desc !== measure[1].after.desc,
    );
  };
}

function featuredMeasuresFunc(measuresArray: [string, unknown][]) {
  return measuresArray.map((measure: any[]) => {
    // add name in Dutch field
    // eslint-disable-next-line default-case
    let nameInDutch;
    switch (measure[0]) {
      case "roof_insulation":
        nameInDutch = "Dakisolatie";
        break;
      case "wall_insulation":
        nameInDutch = "Spouwmuurisolatie";
        break;
      case "solar_panels":
        nameInDutch = "Zonnepanelen";
        break;
      case "floor_insulation":
        nameInDutch = "Vloerisolatie";
        break;
      case "instalation":
        nameInDutch = "Installatie";
        break;
      case "shower":
        nameInDutch = "Douche warmteterugwinning";
        break;
      case "living_room_windows":
        nameInDutch = "Ramen woonkamer";
        break;
      case "bedroom_windows":
        nameInDutch = "Ramen slaapkamer";
        break;
      default:
        nameInDutch = "";
    }

    return [
      measure[0],
      {
        ...measure[1],
        nameInDutch,
      },
    ];
  });
}
