import { useState, useEffect } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getStreetViewImage } from "../../../helpers/common";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  clearRebuildResults,
  modifyRebuildQueries,
} from "../../../redux/actions/rebuildAction";

const RebuildResult = () => {
  const { loading, result } = useAppSelector((state) => state.rebuild);
  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      // Get image from Streetview API
      const { Latitude, Longitude } = property;
      getStreetViewImage(Latitude, Longitude).then((url) =>
        setBuildingPhoto(url),
      );
    }
  }, [property]);

  const history = useHistory();
  const dispatch = useAppDispatch();
  if (Object.keys(property).length === 0) {
    return <Redirect to="/rebuild" />;
  }
  const clearResults = () => {
    dispatch(clearRebuildResults());
    history.push("/rebuild");
  };

  const modifyResults = () => {
    dispatch(modifyRebuildQueries());
    history.push("/rebuild");
  };
  const surfaces = property.roof_surfaces;
  const formattedSurfaces = surfaces.map(
    (surface: {
      orientation: any;
      area: string | number;
      perimeter: string | number;
      angle: number;
    }) => {
      return {
        title: "Oppervlak",
        details: [
          {
            key: "Oriëntatie",
            value: surface.orientation,
          },
          {
            key: "Oppervlak in m2",
            value: convertToEuFormat(surface.area),
          },
          {
            key: "Omtrek",
            value: convertToEuFormat(surface.perimeter),
          },
          {
            key: "Angle",
            value: convertToEuFormat(surface.angle),
          },
        ],
      };
    },
  );
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property?.bag_id,
    },
    {
      img: "",
      title: "Num ID",
      result: property?.num_id,
    },
    {
      img: "",
      title: "Postcode",
      result: property?.postcode,
    },
    {
      img: "",
      title: "House number",
      result: property?.housenumber,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: property?.houseaddition,
    },
    {
      img: "",
      title: "Region",
      result: property?.region,
    },
    {
      img: "",
      title: "City",
      result: property?.city,
    },
    {
      img: "",
      title: "Street",
      result: property?.street,
    },
    {
      img: "",
      title: "Build year",
      result: property?.build_year,
    },
    {
      img: "",
      title: "Inner surface Area",
      result: property?.inner_surface_area,
    },
    {
      img: "",
      title: "Outer surface Area",
      result: property?.outer_surface_area,
    },
    {
      img: "",
      title: "Volume",
      result: property?.volume,
    },
    {
      img: "",
      title: "House Type Generic",
      result: property?.house_type_generic,
    },
    {
      img: "",
      title: "House Type",
      result: property?.house_type,
    },
    {
      img: "",
      title: "Energy Label",
      result: property?.energy_label,
    },
    {
      img: "",
      title: "Energy Calculation Method",
      result: property?.energy_calculation_method,
    },
    {
      img: "",
      title: "Energy Calculation Code",
      result: property?.energy_calculation_code,
    },
    {
      img: "",
      title: "Energy Calculation Code",
      result: property?.energy_calculation_code,
    },
    {
      img: "",
      title: "Energy Calculation Code",
      result: property?.energy_calculation_code,
    },
    {
      img: "",
      title: "Monument Status",
      result: property?.monument_status,
    },
    {
      img: "",
      title: "Usage Indication",
      result: property?.usage_indication,
    },
    {
      img: "",
      title: "Parcel Value",
      result: property?.parcel_value,
    },
    {
      img: "",
      title: "Number of Rooms",
      result: property?.number_of_rooms,
    },
    {
      img: "",
      title: "Number of Extra Buildings",
      result: property?.number_of_extrabuildings,
    },
    {
      img: "",
      title: "Main Building Surface Area",
      result: property?.main_building_surface_area,
    },
    {
      img: "",
      title: "Extra Buildings Surface Area",
      result: property?.extrabuildings_surface_area,
    },
    {
      img: "",
      title: "Listing Status",
      result: property?.listing_status,
    },
    {
      img: "",
      title: "Asking Price",
      result: property?.asking_price,
    },
    {
      img: "",
      title: "Asking Price Date",
      result: property?.asking_price_date,
    },
    {
      img: "",
      title: "Last Sold Date",
      result: property?.last_sold_date,
    },
    {
      img: "",
      title: "Roof Construction",
      result: property?.roof_construction,
    },
    {
      img: "",
      title: "Corrections",
      result: property?.corrections,
    },
    {
      img: "",
      title: "Total Correction",
      result: property?.total_correction,
    },
  ];
  const overview = [
    {
      title: "Resultaat",
      details: [
        {
          key: "Num ID",
          value: property?.num_id,
        },
        {
          key: "Bag ID",
          value: property?.bag_id,
        },
        {
          key: "Pand ID",
          value: property?.pand_id,
        },
        {
          key: "Postcode",
          value: property?.postcode,
        },
        {
          key: "Huisnummer",
          value: property?.housenumber,
        },
        {
          key: "Huis Toevoeging",
          value: property?.houseaddition,
        },
        {
          key: "Regio",
          value: property?.region,
        },
        {
          key: "Stad",
          value: property?.city,
        },
        {
          key: "Straat",
          value: property?.street,
        },
        {
          key: "Bouwjaar",
          value: property?.build_year,
        },
        {
          key: "Woonoppervlakte",
          value: `${convertToEuFormat(property?.inner_surface_area)} m2`,
        },
        {
          key: "Perceeloppervlakte",
          value: `${convertToEuFormat(property?.outer_surface_area)} m2`,
        },
        {
          key: "Inhoud",
          value: `${convertToEuFormat(property?.volume)} m3`,
        },
        {
          key: "Generiek Woningtype",
          value: property?.house_type_generic,
        },
        {
          key: "Woningtype",
          value: property?.house_type,
        },
        {
          key: "Energielabel",
          value: property?.energy_label,
        },
        {
          key: "Energieberekeningsmethode",
          value: property?.energy_calculation_method,
        },
        {
          key: "Energieberekeningscode",
          value: property?.energy_calculation_code,
        },
        {
          key: "Monumentstatus",
          value: property?.monument_status,
        },
        {
          key: "Gebruiksindicatie",
          value: property?.usage_indication,
        },
        {
          key: "Gebouwstatus",
          value: property?.building_status,
        },
        {
          key: "Gebouwfunctie",
          value: property?.building_function,
        },
        {
          key: "Herbouwwaarde",
          value: `€ ${convertToEuFormat(property?.rebuild_value)}`,
        },
        {
          key: "WOZ-waarde",
          value: `€ ${convertToEuFormat(property?.woz_value)}`,
        },
        {
          key: "Kavelwaarde",
          value: `€ ${convertToEuFormat(property?.parcel_value)}`,
        },
        {
          key: "Gebouwwaarde",
          value: `€ ${convertToEuFormat(property?.building_value)}`,
        },
        {
          key: "Aantal Bouwlagen",
          value: property?.number_of_building_layers,
        },
        {
          key: "Type Dak",
          value: property?.roof_type,
        },
      ],
    },
  ];

  const reference = [
    { text: "Verduurzaming advies", path: "/sustainability" },
    { text: "Energielabel vaststellen", path: "/energy-label" },
    { text: "Marktwaarde bepalen", path: "/avm" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Herbouwwaarde API"}
      street={property.HouseNumber + "" + property.Street}
      postCode={property.PostCode}
      city={property.City}
      overview={[...overview, ...formattedSurfaces]}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/herbouwwaarde-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

export default RebuildResult;
