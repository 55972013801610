import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import LocationDataForm from "./locationDataForm";

const LocationData = () => {
  const { loading, result } = useAppSelector((state) => state.locationData);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/location-data-result",
          }}
        />
      ) : (
        <LocationDataForm />
      )}
    </>
  );
};

export default LocationData;
