import { FC } from "react";
import CodeBlock from "../../../../../components/apiForms/Codeblock";
import { FormOptions, FormOptionsSustainability } from "../../../../../@types";
import { stringToBoolean } from "../../../../../helpers/common";

type Props = {
  page: number;
  formValues: FormOptions | FormOptionsSustainability;
  result: any;
};

const JsonViewer: FC<Props> = ({ page, formValues, result }) => {
  const updatedData: FormOptions | FormOptionsSustainability = {
    post_code: formValues.post_code?.split(" ").join("").toUpperCase() || "",
    fetch_definitive_label: 1,
  };

  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== undefined) {
      updatedData[key] = stringToBoolean(value);
    }
  });
  const json = page < 4 ? { data: updatedData } : result;
  return (
    <div className="w-full h-[780px]">
      <CodeBlock text={json} />
    </div>
  );
};

export default JsonViewer;
