import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useState } from "react";
import {
  CheckGoodYes,
  EyePasswordShow,
  EyePasswordHide,
  CopyDuplicate,
} from "react-basicons";
import { APIdata } from "../../components/ApiCard/ApisData";

import useCopy from "../../hooks/useCopy";
import { useAppSelector } from "../../redux/hooks";
import { TextStyles, Colors, device } from "../../styles/Theme";
import { Heading, Main } from "../../styles/styled/Common.styled";

function ApiKey() {
  const { user } = useAppSelector((state) => state.auth);
  const [clicked] = useState(false);
  const [transactie] = useState(false);
  const [showBullet, setShowBullet] = useState(true);

  const [, copyApiKey] = useCopy();

  return (
    <>
      <Helmet>
        <title>API key's - Altum AI</title>
      </Helmet>
      <Heading>API Keys </Heading>
      <Main>
        <Textstyle>
          <p className="title">API Key: </p>
          {showBullet ? (
            <p
              style={{
                fontSize: "30px",
                lineHeight: "10px",
              }}
            >
              ***********
            </p>
          ) : (
            user?.api_key
          )}
          <div onClick={() => user && copyApiKey(user?.api_key)}>
            {clicked ? (
              <CheckGoodYes color="green" />
            ) : (
              <CopyDuplicate color="black" />
            )}
          </div>
          <div onClick={() => setShowBullet(!showBullet)}>
            {showBullet ? <EyePasswordShow /> : <EyePasswordHide />}
          </div>
        </Textstyle>

        <ListStyle>
          {APIdata.filter((item) => item.title !== "Kadaster transacties").map(
            (item, index) => (
              <>
                <ListFlex>
                  <div>
                    <CheckGoodYes color="#1E9D66" />
                  </div>
                  <li key={index}>{item.title}</li>
                </ListFlex>
                <hr />
              </>
            ),
          )}
        </ListStyle>
      </Main>
      {user?.transaction_api_key && (
        <Main>
          <Title style={{ color: "black" }}>Kadaster transacties</Title>
          <Textstyle>
            <p>API Key: </p>
            {showBullet ? (
              <p
                style={{
                  fontSize: "30px",
                  lineHeight: "10px",
                }}
              >
                ***********
              </p>
            ) : (
              user.transaction_api_key
            )}
            <div onClick={() => copyApiKey(user.transaction_api_key)}>
              {transactie ? (
                <CheckGoodYes color="green" />
              ) : (
                <CopyDuplicate color="black" />
              )}
            </div>
            <div onClick={() => setShowBullet(!showBullet)}>
              {showBullet ? <EyePasswordShow /> : <EyePasswordHide />}
            </div>
          </Textstyle>

          <ListFlex>
            <div>
              <CheckGoodYes color="#1E9D66" />
            </div>
            <li>Kadaster transactie</li>
          </ListFlex>
        </Main>
      )}
    </>
  );
}
export default ApiKey;

const Title = styled.div`
  ${TextStyles.Bundler(TextStyles.Label.Small)};
  margin-bottom: 20px;
  color: ${Colors.main.green};

  @media ${device.tablet} {
    margin-left: 2rem;
  }
  @media ${device.mobileL} {
    margin-left: 0rem;
  }
`;

const Textstyle = styled.div`
  ${TextStyles.Bundler(TextStyles.Text.P)};
  color: black;
  max-width: 50%;
  word-break: break-word;
  padding: 20px;
  border-radius: 30px;
  border: 3px solid ${Colors.main.lightgrey};
  background-color: ${Colors.main.white};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    font-weight: 500;
  }

  div {
    margin-top: 5px;
  }

  a {
    color: ${Colors.main.green};
  }

  @media ${device.laptopL} {
    min-width: 100%;
  }
  @media ${device.tablet} {
    min-width: 100%;
    .title {
      display: none;
    }
  }

  @media ${device.mobileL} {
    gap: 10px;
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2rem;

    .title {
      display: none;
    }
  }
`;

// const Main = styled.div`
// 	background: ${Colors.main.white};
// 	display: flex;
// 	flex-direction: column;
// 	padding: 2rem;
// 	background: ${Colors.main.white};
// 	box-shadow: 0px 2px 35px rgba(5, 27, 105, 0.06);
// 	border-radius: 20px;
// 	width: 100%;
// 	position: relative;
// 	align-items: left;
// `;

const ListStyle = styled.ul`
  display: flex;
  flex-direction: column;
  color: black;
  gap: 20px;
  li {
  }
`;

const ListFlex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${Colors.main.mediumGreen};
    display: grid;
    place-content: center;
  }
`;
