import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import KadasterForm from "./KadasterForm";
import Loading from "../../Loading";

const Kadaster = () => {
  const { loading, result } = useAppSelector((state) => state.transaction);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/transactie-result",
          }}
        />
      ) : (
        <KadasterForm />
      )}
    </>
  );
};

export default Kadaster;
