import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import EnergyClimateForm from "./EnergyClimateForm";

const EnergyClimate = () => {
  const { loading, result } = useAppSelector((state) => state.energyClimate);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/energy-climate-result",
          }}
        />
      ) : (
        <EnergyClimateForm />
      )}
    </>
  );
};

export default EnergyClimate;
