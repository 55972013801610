import {
  AVM,
  ConditionScore,
  Ecovalue,
  EnergyLabel,
  ImageApi,
  LocationData,
  MoveData,
  ObjectGeometry,
  ReferenceImage,
  TransactionAPI,
  WOZ,
  EnergyInsightImg,
  ObjectData,
  solarscan,
  rentalReference,
  autosuggest,
  rebuild,
  energyClimate,
} from "../../assets/images/api/APIimages";

export const APIdata = [
  {
    title: "Verduurzaming advies",
    text: "Start een verduurzamingsadvies en ontdek de kosten, besparingen en terugverdientijd voor een specifiek object.",
    img: Ecovalue,
    link: "/sustainability?step=1",
    category: "Verduurzamen",
  },

  {
    title: "Kadaster transacties",
    text: "Vraag koopsommen en relevante transactiegegevens op uit de Kadaster database t/m 1993 terug.",
    img: TransactionAPI,
    link: "/transactie-api",
    category: "Taxeren",
  },
  {
    title: "Woning referenties opvragen",
    text: "Een volledig interactieve tool voor het opvragen van relevante woningtransacties uit het Kadaster.",
    img: ReferenceImage,
    link: "/referentie",
    category: "Taxeren",
  },
  {
    title: "Woningwaarde model",
    text: "Vraag een indicatie van de woningwaarde op met relevante Woningdata en accuratesse indicator.",
    img: AVM,
    link: "/avm",
    category: "Taxeren",
  },
  {
    title: "WOZ waarde",
    text: "Raadpleeg de actuele WOZ-waardes uit de database die wordt vastgesteld door Gemeenten.",
    img: WOZ,
    link: "/woz",
    category: "Taxeren",
  },

  {
    title: "Locatie-gegevens",
    text: "Vraag relevante postcode - en buurtniveau op zoals demografische gegevens, woningtypes en bodemsoort",
    img: LocationData,
    link: "/location-data",
    category: "Woningdata",
  },
  {
    title: "Energielabel inzichten API",
    text: "De Energielabel inzichten API retourneert de impact van een energielabelwijziging op de verkoopduur en het verschil tussen de verwachte transactieprijs en de vraagprijs",
    img: EnergyInsightImg,
    link: "/energy-insights",
    category: "Verduurzamen",
  },
  {
    title: "Verhuisdata",
    text: "Als een woning online wordt aangeboden is de data op te vragen voor de woning en de omgeving.",
    img: MoveData,
    link: "/verhuisdata",
    category: "Woningdata",
  },

  {
    title: "Foto labelen",
    text: "Label woning foto's, zowel binnen als buiten, om te prioriteren en te controleren op aanwezigheid. ",
    img: ImageApi,
    link: "/foto-labelen",
    category: "Foto's",
  },
  {
    title: "Conditie score voor badkamer en keuken",
    text: "Het model is te gebruiken om de conditiescore van badkamers en keukens te waarderen op een vijfpuntsschaal van uitstekend tot vervallen.",
    img: ConditionScore,
    link: "/conditie-score",
    category: "Foto's",
  },
  {
    title: "Object geometrie opvragen",
    text: "Gebruikt objectgegevens en satellietbeelden met hoge resolutie om de vorm en afmetingen van gebouwen te bepalen voor het genereren van 3D-modellen.",
    img: ObjectGeometry,
    link: "/object-geometry",
    category: "Verduurzamen",
  },
  {
    title: "NTA 8800 Energy label API",
    text: "Bepaal het geschatte huidige of potentiële energielabel volgens de NTA 8800 norm op basis van de informatie over de huidige of verwachte situatie van het pand.",
    img: EnergyLabel,
    link: "/energy-label",
    category: "Verduurzamen",
  },
  {
    title: "Woningkenmerken API",
    text: "Deze API biedt een enkel woning object in JSON-formaat aan, datverschillende details bevat over het type huis, oppervlaktes en meer.",
    img: ObjectData,
    link: "/objectdata",
    category: "Woningdata",
  },
  {
    title: "Herbouwwaarde API",
    text: "Berekening van de herbouwwaarde van woningen t.b.v. opstalverzekeringen.",
    img: rebuild,
    link: "/rebuild",
    category: "Verzekeren",
    isNew: true,
  },
  {
    title: "Zonnepanelen dakenscan",
    text: "Berekent de optimale indeling van zonnepanelen in termen van aantal, positie etc., rekening houdend met de oriëntatie en geometrie van het dak.",
    img: solarscan,
    link: "/solarscan",
    category: "Verduurzamen",
    isNew: true,
  },
  {
    title: "Autosuggestie API",
    text: "Vraag de Autosuggestie API voor een Nederlandse woning aan om een automatische suggestie te krijgen",
    img: autosuggest,
    link: "/autosuggest",
    category: "Woningdata",
    isNew: true,
  },
  {
    title: "Huurreferentie API",
    text: "Berekening van de herbouwwaarde van woningen t.b.v. opstalverzekeringen.",
    img: rentalReference,
    link: "/rentalreference",
    category: "Beleggen",
    isNew: true,
  },
  {
    title: "Energie & klimaat API",
    text: "Voor het opvragen van alle relevante BAG-, energielabel- & klimaatgegevens voor een woning.",
    img: energyClimate,
    link: "/energy-climate",
    category: "Verduurzamen",
    isNew: true,
  },
];
