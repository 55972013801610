import { FormEvent, useEffect, useState } from "react";
import { ObjectDataField } from "./objectDataFields";
import FormBody from "../FormBody";
import { ObjectData } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { postObjectData } from "../../../redux/actions/objectDataActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const ObjectDataForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });
  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey &&
      dispatch(postObjectData({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Woningkenmerken API"}
        sectionFields={sectionFields}
        desc={
          "Deze API biedt een verschillende details over een enkel woning object zoals het woningtype, oppervlaktes, tuin oriëntatie, monument status en meer."
        }
        img={ObjectData}
        path={""}
        initialFields={ObjectDataField}
        handleSubmit={handleFormSubmit}
        setFormValues={setHouseOptions}
        formValues={houseOptions}
        options="Geef een waarderingsdatum op informatie over het object te vinden van vóór die datum"
      />
    </div>
  );
};

export default ObjectDataForm;
