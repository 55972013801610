import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import APIResult, { LightText } from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import {
  clearTransactionResults,
  modifyTransactionQueries,
} from "../../../redux/actions/transactionApiAction";

const houseType = (type: string) => {
  switch (type) {
    case "A":
      return "Appartement";
    case "H":
      return "Hoekwoning";
    case "K":
      return "Twee onder 1 kap";
    case "O":
      return "Onbekend";
    case "T":
      return "Tussenwoning";
    case "V":
      return "Vrijstaand";
  }
};

const KadasterResult = () => {
  const { result, loading } = useAppSelector((state) => state.transaction);

  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearTransactionResults());
    history.push("/transactie-api");
  };

  const modifyResults = () => {
    dispatch(modifyTransactionQueries());
    history.push("/transactie-api");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/transactie-api" />;
  }
  const buildingCode = (code: string) => {
    switch (code) {
      case "11":
        return "Wonen";

      case "12":
        return "Appartement";

      case "14":
        return "Agrarisch";

      case "37":
        return "Wonen met bedrijvigheid";

      case "53":
        return "Recreatiewoning";
    }
  };

  const boolType = (code: string) => {
    switch (code) {
      case "N":
        return "Nee";
      case "J":
      case "Y":
        return "Ja";
    }
  };
  const desc = [
    { img: "", title: "Transaction", result: property[0].Transaction },
    {
      img: "",
      title: "Potentiële Ecowaarde",
      result: property[0].BusinessRight,
    },
    {
      img: "",
      title: "Gebouwcode",
      result: houseType(property[0].HouseType),
    },
    {
      img: "",
      title: "Verkoopdatum",
      result: property[0].TransactionDate,
    },
  ];

  const overview = [
    {
      title: "Koopsomdata",
      details: [
        {
          key: "Transaction",
          value: property[0].Transaction,
        },
        {
          key: "Postcode",
          value: property[0].PostCode,
        },
        {
          key: "Huisnummer",
          value: property[0].HouseNumber,
        },
        {
          key: "Huisnummer toevoeging",
          value: `${
            property[0].HouseAddition ? property[0].HouseAddition : "-"
          }`,
        },
        {
          key: "Straat",
          value: property[0].Street,
        },
        {
          key: "Woonplaats",
          value: property[0].City,
        },
      ],
    },
    {
      title: "Resultaat",
      details: [
        {
          key: "Koopsom",
          value: convertToEuFormat(property[0].TransactionPrice),
        },
        {
          key: "Verkoopdatum",
          value: property[0].TransactionDate,
        },
        {
          key: "Woningtype",
          value: houseType(property[0].HouseType),
        },
        {
          key: "Gebouwcode",
          value: buildingCode(property[0].BuildingCode),
        },
        {
          key: "Meerdere objecten in transactie",
          value: boolType(property[0].MoreRealEstate),
        },
        {
          key: "Indicatie verhuurde staat",
          value: boolType(property[0].RentedOut),
        },
        {
          key: "Zakelijk recht",
          value: property[0].BusinessRight,
        },
        {
          key: "Perceeloppervlakte",
          value: `${convertToEuFormat(property[0].OuterSurfaceArea)} m2`,
        },
        {
          key: "Familietransactie",
          value: boolType(property[0].FamilyTransaction),
        },
      ],
    },
  ];

  const reference = [
    { text: "Marktwaarde bepalen", path: "/avm" },
    {
      text: "Referenties opvragen",
      path: "/referentie",
    },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];

  return (
    <APIResult
      property={property[0]}
      loading={loading}
      title={"Kadaster transactie gegevens"}
      street={`${property[0].Street} ${property[0].HouseNumber}`}
      postCode={property[0].PostCode}
      city={property[0].City}
      overview={overview}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      result={result}
      path="https://docs.altum.ai/apis/transaction-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
      description={<TransactionDesc property={property} />}
    />
  );
};

const TransactionDesc: React.FC<{ property: any }> = ({ property }) => {
  return (
    <LightText style={{ marginBottom: "20px", textAlign: "left" }}>
      Voor de woning met het type{" "}
      <strong> {houseType(property[0].HouseType)}</strong>, gelegen aan de{" "}
      <strong>
        {`${property[0].Street} ${property[0].HouseNumber} ${
          property[0].HouseAddition ? property[0].HouseAddition : ""
        } ${property[0].City}`}
      </strong>{" "}
      is de laatst bekende transactie van{" "}
      <strong>{property[0].TransactionDate}</strong> met een koopsom van €{" "}
      <strong>{convertToEuFormat(property[0].TransactionPrice)}</strong>.
    </LightText>
  );
};

export default KadasterResult;
