import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import ObjectGeometryForm from "./ObjectGeometryForm";
import Loading from "../../Loading";

const ObjectGeometry = () => {
  const { loading, result } = useAppSelector((state) => state.objectGeometry);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/object-geometry-result",
          }}
        />
      ) : (
        <ObjectGeometryForm />
      )}
    </>
  );
};

export default ObjectGeometry;
