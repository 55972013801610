import { MoveFields } from "../move/movefields";
import FormBody from "../FormBody";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { LocationData } from "../../../assets/images/api/APIimages";
import { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postLocationData } from "../../../redux/actions/locationDataActions";
import { LocationDataFields } from "./locationDataField";

const LocationDataForm = () => {
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });
  const { savedQueries } = useAppSelector((state) => state.moveData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey &&
      dispatch(postLocationData({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <FormBody
      title={"Locatie-gegevens API"}
      desc={
        "Met de Locatie-gegevens API haal je data op van objecten actief op de woningmarkt."
      }
      path={"https://docs.altum.ai/apis/locatie-gegevens-api"}
      initialFields={LocationDataFields}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
      setFormValues={setHouseOptions}
      formValues={houseOptions}
      img={LocationData}
    />
  );
};

export default LocationDataForm;
