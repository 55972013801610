import { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { postEnergyInsight } from "../../../redux/actions/energyInsightActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { energyInsightsFields } from "./energyInsightFields";
import { EnergyInsightImg } from "../../../assets/images/api/APIimages";

const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 4 }];
const EnergyInsightForm = () => {
  const { savedQueries } = useAppSelector((state) => state.energyInsight);
  const energyInsightSavedQueries = savedQueries as FormOptionsSustainability;
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const sliderCircle1 = document.querySelector(
      "#target_energy_label > div:last-child > div:last-child > div",
    ) as HTMLElement;
    const sliderCircle2 = document.querySelector(
      "#current_energy_label > div:last-child > div:last-child > div",
    ) as HTMLElement;
    const track1 = document.querySelector(
      "#target_energy_label > div:first-child > div",
    );
    const track2 = document.querySelector(
      "#current_energy_label > div:first-child > div",
    );
    track1 && track1.classList.add("target_energy_label-track");
    track2 && track2.classList.add("current_energy_label-track");

    sliderCircle1.classList.add("form-slider");
    sliderCircle1.style.backgroundColor = "rgba(0,166,82,255)";
    sliderCircle1.textContent =
      energyInsightSavedQueries?.target_energy_label || "A";

    sliderCircle2.classList.add("form-slider");
    sliderCircle2.style.backgroundColor = "grey";
    sliderCircle2.textContent =
      energyInsightSavedQueries?.current_energy_label || "A";
  }, []);

  const [addressFields, setAddressFields] = useState<
    FormOptionsSustainability | FormOptions
  >({
    postcode: "",
    housenumber: "",
    target_energy_label: "A",
    ecovalue: 1,
  });

  useEffect(() => {
    if (Object.keys(energyInsightSavedQueries).length > 0) {
      setAddressFields({
        ...(energyInsightSavedQueries.data as FormOptions),
      });
    }
  }, [energyInsightSavedQueries, energyInsightSavedQueries.data]);

  const handleChange = (e: any, a?: any) => {
    setAddressFields({ ...addressFields, [e.target.name]: undefined });
  };

  const handleEnergyInsightFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedData: FormOptions | FormOptionsSustainability = {
      postcode: "",
      housenumber: "",
      target_energy_label: "",
    };

    if (addressFields.post_code) {
      // It's safe to access addressFields.post_code now css-mvtjcd-Slider
      updatedData.post_code = addressFields.post_code
        .split(" ")
        .join("")
        .toUpperCase();
    }
    Object.entries(addressFields).forEach(([key, value]) => {
      if (value !== undefined) {
        updatedData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postEnergyInsight({ formData: updatedData, apiKey }));
  };

  return (
    <FormBody
      title={"Energielabel inzichten API"}
      desc={
        "De Energielabel inzichten API retourneert de impact van een energielabelwijziging op de verkoopduur en het verschil tussen de verwachte transactieprijs en de vraagprijs."
      }
      path={"https://docs.altum.ai/apis/energielabel-inzichten-api"}
      img={EnergyInsightImg}
      sectionFields={sectionFields}
      initialFields={energyInsightsFields}
      handleSubmit={handleEnergyInsightFormSubmit}
      setFormValues={setAddressFields}
      formValues={addressFields}
      inputChange={handleChange}
      options={
        "Overschrijf de standaard uitgangspunten en zoekcriteria voor een geoptimaliseerd resultaat"
      }
    />
  );
};

export default EnergyInsightForm;
