import { useState, useEffect } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getStreetViewImage } from "../../../helpers/common";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory } from "react-router-dom";
import { clearGeometryResults } from "../../../redux/actions/objectGeometryActions";
import { modifyGeometryQueries } from "../../../redux/actions/objectGeometryActions";
import { Redirect } from "react-router-dom";

const ObjectGeometryResult = () => {
  const { loading, result } = useAppSelector((state) => state.objectGeometry);
  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      // Get image from Streetview API
      const { Latitude, Longitude } = property;
      getStreetViewImage(Latitude, Longitude).then((url) =>
        setBuildingPhoto(url),
      );
    }
  }, [property]);

  const history = useHistory();
  const dispatch = useAppDispatch();
  if (Object.keys(property).length === 0) {
    return <Redirect to="/object-geometry" />;
  }
  const clearResults = () => {
    dispatch(clearGeometryResults());
    history.push("/object-geometry");
  };

  const modifyResults = () => {
    dispatch(modifyGeometryQueries());
    history.push("/object-geometry");
  };
  const surfaces = property.roof_surface.surfaces;
  const formattedSurfaces = surfaces.map(
    (surface: {
      orientation: any;
      area: string | number;
      perimeter: string | number;
    }) => {
      return {
        title: "Oppervlak",
        details: [
          {
            key: "Oriëntatie",
            value: surface.orientation,
          },
          {
            key: "Oppervlak in m2",
            value: convertToEuFormat(surface.area),
          },
          {
            key: "Omtrek",
            value: convertToEuFormat(surface.perimeter),
          },
        ],
      };
    },
  );
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property?.address.bag_id,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${
        property?.address.houseaddition ? property?.address.houseaddition : "-"
      }`,
    },
    // {
    //   img: "",
    //   title: "Latitude",
    //   result: property?.Latitude,
    // },
    // {
    //   img: "",
    //   title: "Longitude",
    //   result: property?.Longitude,
    // },
  ];

  const overview = [
    {
      title: "Grondoppervlaktes",
      details: [
        {
          key: "Oppervlakte (m2)",
          value: convertToEuFormat(property?.ground_surface.ground_area),
        },
        {
          key: "Omtrek",
          value: convertToEuFormat(property?.ground_surface.perimeter),
        },
        {
          key: "Maximale lengte",
          value: convertToEuFormat(property?.ground_surface.max_length),
        },
        {
          key: "Maximale breedte",
          value: convertToEuFormat(property?.ground_surface.max_width),
        },
      ],
    },

    {
      title: "Dakoppervlaktes",
      details: [
        {
          key: "Daktype",
          value: property?.roof_surface.type,
        },
        {
          key: "Aantal oppervlaktes",
          value: property?.roof_surface.no_of_surfaces,
        },
      ],
    },
    {
      title: "Wandoppervlak",
      details: [
        {
          key: "Oppervlakte",
          value: convertToEuFormat(property?.wall_surface.wall_area),
        },
        {
          key: "Gedeeld muurgebied",
          value: convertToEuFormat(property?.wall_surface.shared_wall_area),
        },
        {
          key: "Aantal oppervlakken",
          value: convertToEuFormat(property?.wall_surface.number_of_surfaces),
        },
      ],
    },
  ];

  const reference = [
    { text: "Verduurzaming advies", path: "/sustainability" },
    { text: "Energielabel vaststellen", path: "/energy-label" },
    { text: "Marktwaarde bepalen", path: "/avm" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Object Geometry - Altum AI"}
      street={property.HouseNumber + "" + property.Street}
      postCode={property.PostCode}
      city={property.City}
      overview={[...overview, ...formattedSurfaces]}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/object-geometrie-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

export default ObjectGeometryResult;
