export function knowWhen(t: Date | string | number) {
  // Function to parse the custom date format with consideration for the timezone
  function parseCustomDate(dateString: string) {
    // Regular expression to extract the date and time components and the timezone
    const parts = dateString.match(
      /^(\d{2})\/([a-zA-Z]{3})\/(\d{4}):(\d{2}):(\d{2}):(\d{2}) \+(\d{4})$/,
    );
    if (!parts) {
      throw new Error("Invalid date format");
    }

    const day = parseInt(parts[1], 10);
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ].indexOf(parts[2]);
    const year = parseInt(parts[3], 10);
    const hours = parseInt(parts[4], 10);
    const minutes = parseInt(parts[5], 10);
    const seconds = parseInt(parts[6], 10);
    // Considering the timezone offset to be always +0000, the timezone handling can be simplified
    // For different timezones, this part needs to convert the offset to milliseconds and adjust the date accordingly
    const utcDate = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds),
    );
    return utcDate;
  }

  let adDate;
  // Check if the input is already a Date object
  if (t instanceof Date) {
    adDate = t;
  } else if (typeof t === "string" && t.includes("/")) {
    adDate = parseCustomDate(t);
  } else {
    adDate = new Date(t);
  }

  const tDelta = Date.now() - adDate.getTime();
  const $1m = 1000 * 60;
  const $1h = $1m * 60;
  const { floor } = Math;
  const timeString =
    adDate.toLocaleDateString("en-US") +
    " at " +
    adDate.toLocaleTimeString("en-US");
  let when = "";
  if (tDelta < 0) {
    when = "in the future";
  } else if (tDelta <= $1m) {
    when = "just now";
  } else if (tDelta < $1h) {
    when = floor(tDelta / $1m) + " minutes ago";
  } else if (tDelta < 24 * $1h) {
    const today = new Date().getDay();
    const adDay = adDate.getDay();
    if (today === adDay) {
      when = "today at " + adDate.toLocaleTimeString("en-US");
    } else {
      when = floor(tDelta / $1h) + " hours ago";
    }
  } else {
    when = timeString;
  }
  return when;
}
