import DashboardContainer from "../components/Container";
import Header from "../components/Header";
import RecommendedAPI from "./components/RecommendedAPI";
import ActivePlanCard from "./components/ActivePlanCard";
import TotalAPICallCard from "./components/TotalAPICallCard";
import APILogs from "./components/APILogs";
import SelectInput from "../../../components/SelectInput";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import calculatePercentageDifference from "../../../helpers/percentDifference";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { getUsageLogs } from "../../../redux/actions/apiUsage";
import { knowWhen } from "../../../helpers/time";
import { isPPUPlan, isTransactionPlan } from "../../../helpers/stripeHelper";
import timeConverter from "../../../helpers/timeStampConverter";
import useIsomorphicLayoutEffect from "../../../hooks/useIsomorphicLayoutEffect";
import apiNameConverter from "../../../helpers/apiNameConverter";
import ActivePlanCardSkelecton from "./components/ActivePlanCardSkelecton";
import APILogSkeleton from "./components/APILogSkeleton";
import { Log } from "../../../@types";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  useIsomorphicLayoutEffect(() => {
    dispatch(getUsageLogs());
  }, [dispatch]);

  const {
    usage: {
      plan,
      totalCredits,
      used,
      transactionUsed,
      remaining,
      usageToday,
      usageYesterday,
      usageLastWeek,
      usageThisWeek,
      subscription,
      loading,
    },
    usageLogs: { loading: logsLoading, logs },
  } = useAppSelector((state) => state.apiUsage);

  const {
    comingInvoice,
    transactionComingInvoice,
    loading: porterLoading,
  } = useAppSelector((state) => state.portal);

  const options = useMemo(
    () =>
      [
        !!comingInvoice?.product.name
          ? comingInvoice?.product.name
          : plan === "Platform - Free Tier"
          ? "Altum AI - gratis account"
          : "-",

        transactionComingInvoice?.product.name,
      ].filter(Boolean),
    [comingInvoice?.product.name, plan, transactionComingInvoice?.product.name],
  );
  const [currentPlan, setCurrentPlan] = useState(options[0]);
  useEffect(() => {
    if (comingInvoice?.product.name) {
      setCurrentPlan(comingInvoice.product.name);
    }
  }, [comingInvoice?.product.name]);

  useEffect(() => {
    if (plan === "Platform - Free Tier") {
      setCurrentPlan("Altum AI - gratis account");
    }
  }, [plan]);

  const { isPPU, isTransaction } = useMemo(
    () => ({
      isPPU: !!currentPlan && isPPUPlan(currentPlan),
      isTransaction: !!currentPlan && isTransactionPlan(currentPlan),
    }),
    [currentPlan],
  );

  const dailyPercentageUsage = useMemo(
    () => calculatePercentageDifference(usageYesterday, usageToday),
    [usageYesterday, usageToday],
  );

  const formattedLogs = useMemo(
    () =>
      logs?.map((log: Log) => ({
        ...log,
        api_name: apiNameConverter(log.api_name),
        requestTime: knowWhen(log.requestTime),
      })),
    [logs],
  );

  return (
    <>
      <DashboardContainer pageTitle="Dashboard - Altum AI">
        <Header
          title="Overzicht"
          subtitle="Hier vind je een overzicht van jouw API activiteiten"
        />
        <div className="w-48 mb-8 transition-all">
          {options.length > 1 && (
            <SelectInput
              options={options}
              onChange={(e) => {
                setCurrentPlan(e.target.value);
              }}
            />
          )}
        </div>
        <div className="flex gap-8 transition-all">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col md:flex-row gap-8">
              {porterLoading || loading ? (
                <ActivePlanCardSkelecton />
              ) : (
                <ActivePlanCard
                  currentPlan={porterLoading ? "-" : currentPlan}
                  creditUsed={
                    isPPU ? used : isTransaction ? transactionUsed : remaining
                  }
                  totalCredit={totalCredits}
                  renewalDate={
                    isPPU
                      ? timeConverter(
                          comingInvoice?.subscription.current_period_end,
                        )
                      : isTransaction
                      ? timeConverter(
                          transactionComingInvoice?.subscription
                            .current_period_end,
                        )
                      : getFirstOfNextMonth().toString()
                  }
                  nextInvoices={
                    isPPU && !!comingInvoice
                      ? convertToEuFormat(
                          comingInvoice?.comingEvent.amount_due / 100,
                        ).toString()
                      : isTransaction && !!transactionComingInvoice
                      ? convertToEuFormat(
                          transactionComingInvoice?.comingEvent.amount_due /
                            100,
                        ).toString()
                      : "-"
                  }
                  usagePercentage={dailyPercentageUsage}
                />
              )}

              {porterLoading || loading ? (
                <ActivePlanCardSkelecton />
              ) : (
                <TotalAPICallCard
                  usageToday={usageToday}
                  usageYesterday={usageYesterday}
                  usageThisWeek={usageThisWeek}
                  usageLastWeek={usageLastWeek}
                />
              )}
            </div>
            <div className="lg:hidden">
              <RecommendedAPI />
            </div>
            {logsLoading && logs?.length === 0 ? (
              <APILogSkeleton />
            ) : (
              <APILogs logs={formattedLogs} />
            )}
          </div>
          <div className="hidden lg:block">
            <RecommendedAPI />
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};

export default Dashboard;

function getFirstOfNextMonth(): string {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
  const nextMonthYear = currentMonth === 11 ? currentYear + 1 : currentYear;

  const firstOfNextMonth = new Date(nextMonthYear, nextMonth, 1);
  return firstOfNextMonth.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}
