import React from "react";
import APIResult, { LightText } from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearEnergyInsightResults,
  modifyEnergyInsightQueries,
} from "../../../redux/actions/energyInsightActions";
import EnergyLabelContainer, { EnergyLabel } from "../EnergyLabel";

const EnergyInsightResult = () => {
  const { result, loading } = useAppSelector((state) => state.energyInsight);
  const property: any = result;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearEnergyInsightResults());
    history.push("/energy-insights");
  };

  const modifyResults = () => {
    dispatch(modifyEnergyInsightQueries());
    history.push("/energy-insights");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/energy-insights" />;
  }
  const desc = [
    { img: "", title: "Type Woning", result: property?.building?.type },
    {
      img: "",
      title: "Potentiële Ecowaarde",
      result: "€" + convertToEuFormat(property?.financial?.eco_value),
    },
    {
      img: "",
      title: "Type Woning",
      result:
        property?.comfortscore?.current +
        " > " +
        property?.comfortscore?.potential,
    },
    {
      img: "",
      title: "Energielabel",
      result: property?.label?.current + " > " + property?.label?.potential,
    },
  ];

  const overview = [
    {
      title: "Huidige inzichten",
      details: [
        {
          key: "Gelaagdheid",
          value: property?.current_insights.stratification,
          unit: "",
        },
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(
            property?.current_insights.mean_sales_duration,
          ),
          unit: "",
        },
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(
            property?.current_insights.median_sales_duration,
          ),
          unit: "",
        },
        {
          key: "Gemiddelde prijsverschil",
          value: `€ ${convertToEuFormat(
            property?.current_insights.mean_diff_price,
          )}`,
          unit: "",
        },
        {
          key: "Gemiddelde verschilprijs",
          value: `€ ${convertToEuFormat(
            property?.current_insights.median_diff_price,
          )}`,
          unit: "",
        },
      ],
    },
    {
      title: "Doelinzichten",
      details: [
        {
          key: "Gelaagdheid",
          value: property?.target_insights.stratification,
          unit: "",
        },
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(
            property?.target_insights.mean_sales_duration,
          ),
          unit: "",
        },
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(
            property?.target_insights.median_sales_duration,
          ),
          unit: "",
        },
        {
          key: "Gemiddelde prijsverschil",
          value: `€ ${convertToEuFormat(
            property?.target_insights.mean_diff_price,
          )}`,
          unit: "",
        },
        {
          key: "Gemiddelde verschilprijs",
          value: `€ ${convertToEuFormat(
            property?.target_insights.median_diff_price,
          )}`,
          unit: "",
        },
      ],
    },
    {
      title: "Delta",
      details: [
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(property?.delta.mean_sales_duration),
          unit: "",
        },
        {
          key: "Gemiddelde verkoopduur",
          value: convertToEuFormat(property?.delta.median_sales_duration),
          unit: "",
        },
        {
          key: "Gemiddelde prijsverschil",
          value: `€ ${convertToEuFormat(property?.delta.mean_diff_price)}`,
          unit: "",
        },
        {
          key: "Gemiddelde verschilprijs",
          value: `€ ${convertToEuFormat(property?.delta.median_diff_price)}`,
          unit: "",
        },
        {
          key: "Bedoel een ander prijspercentage",
          value: convertToEuFormat(property?.delta.mean_diff_price_percentage),
          unit: "%",
        },
        {
          key: "Mediaan verschillend prijspercentage",
          value: convertToEuFormat(
            property?.delta.median_diff_price_percentage,
          ),
          unit: "%",
        },
        {
          key: "Ecowaarde",
          value: convertToEuFormat(property?.delta.ecovalue),
          unit: "",
        },
      ],
    },
  ];

  const reference = [
    { text: "Objectgeometrie opvragen", path: "/object-geometry-demo" },
    { text: "Energielabel vaststellen", path: "/energy-label-demo" },
    { text: "Marktwaarde bepalen", path: "/avm-demo" },
  ];

  return (
    <>
      {Object.keys(result).length > 0 && (
        <APIResult
          property={property}
          loading={loading}
          title={"Energielabel inzichten resultaat - Altum AI"}
          street={
            property?.address?.street + " " + property?.address?.house_number
          }
          postCode={property?.address?.post_code}
          city={property?.address?.city}
          descSection={desc}
          overview={overview}
          reference={reference}
          result={result}
          path="https://docs.altum.ai/apis/energielabel-inzichten-api"
          clearResult={clearResults}
          modifyResult={modifyResults}
          description={<EnergyInsightDesc property={property} />}
          //   PDF={DownloadReport}
        />
      )}
    </>
  );
};

const EnergyInsightDesc: React.FC<{ property: any }> = ({ property }) => {
  const [current, district, houseType, buildingPeriod, surfaceArea] =
    property?.current_insights.stratification
      .substring(2, property?.current_insights.stratification.length - 2)
      .split("', '");
  const [target] = property?.target_insights.stratification
    .substring(1, property?.target_insights.stratification.length - 2)
    .split(",");
  console.log();
  return (
    <LightText style={{ marginBottom: "20px", textAlign: "left" }}>
      Voor een woning met het type <strong>{houseType}</strong>, uit de wijk{" "}
      <strong>{district}</strong>, gebouwd in de periode{" "}
      <strong>{buildingPeriod}</strong> met oppervlakte van{" "}
      <strong>{surfaceArea}</strong> is bij een wijziging van label{" "}
      <EnergyLabelContainer
        label={EnergyLabel[current as keyof typeof EnergyLabel]}
      />{" "}
      naar{" "}
      <EnergyLabelContainer
        label={
          EnergyLabel[target.replace(/'/g, "") as keyof typeof EnergyLabel]
        }
      />{" "}
      de geschatte marktwaarde delta{" "}
      <strong>{"€" + convertToEuFormat(property?.delta.ecovalue)}</strong> en de
      invloed op de verkoopduur{" "}
      <strong>{property?.delta.mean_sales_duration}</strong> dagen.
    </LightText>
  );
};

export default EnergyInsightResult;
