import React, { FC } from "react";
import { Helmet } from "react-helmet";

type Props = {
  children: React.ReactNode;
  pageTitle: string;
};

const DashboardContainer: FC<Props> = ({ pageTitle, children }) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="p-4 lg:px-20 md:w-auto lg:w-full">{children}</div>
    </>
  );
};

export default DashboardContainer;
