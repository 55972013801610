import { useState, useEffect } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getStreetViewImage, splitAddress } from "../../../helpers/common";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearObjectDataResults,
  modifyObjectDataQueries,
} from "../../../redux/actions/objectDataActions";

const ObjectDataResult = () => {
  const { result, loading } = useAppSelector((state) => state.objectData);
  const property: any = result;
  const [buildingPhoto, setBuildingPhoto] = useState<string>("");
  useEffect(() => {
    if (Object.keys(property).length > 0) {
      // Get image from Streetview API
      const { latitude, longitude } = property;
      getStreetViewImage(latitude, longitude).then((url) =>
        setBuildingPhoto(url),
      );
    }
  }, [property]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearObjectDataResults());
    history.push("/objectdata");
  };

  const modifyResults = () => {
    dispatch(modifyObjectDataQueries());
    history.push("/objectdata");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/objectdata" />;
  }
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.bag_id,
    },
    {
      img: "",
      title: "Waarderingsdatum",
      result: "€" + property.validation_date,
    },
    {
      img: "",
      title: "Latitude",
      result: property.latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.longitude,
    },
  ];

  const overview = [
    {
      title: "Woningdata",
      details: [
        {
          key: "Woningtype",
          value: property.house_type_generic,
        },
        {
          key: "Specifiek type",
          value: property.property_date,
        },
        {
          key: "Constructie",
          value: property.type_house_construction,
        },
        {
          key: "Pand status",
          value: property.building_status,
        },
        {
          key: "Woonoppervlakte (m2)",
          value: `${convertToEuFormat(property.inner_surface_area)} m2`,
        },
        {
          key: "Perceeloppervlakte (m2)",
          value: `${convertToEuFormat(property.outer_surface_area)} m2`,
        },
        {
          key: "Bouwjaar",
          value: property.build_year,
        },
        {
          key: "Inhoud",
          value: `${convertToEuFormat(property.volume)} m3`,
        },
        {
          key: "Kamers",
          value: property.rooms,
        },
        {
          key: "Badkamers",
          value: property.number_of_bathrooms,
        },
        {
          key: "Tuinoriëntatie",
          value: property.garden_orientation,
        },
        {
          key: "Tuinoppervlakte",
          value: property.garden_size,
        },
        {
          key: "Tuinlocatie",
          value: property.garden_location,
        },

        {
          key: "Monumentstatus",
          value: property.monument,
        },
        {
          key: "Monument omschrijving",
          value: property.monument_description,
        },
        {
          key: "Longitude",
          value: property.longitude,
        },
        {
          key: "Latitude",
          value: property.latitude,
        },
        {
          key: "Buurtcode",
          value: property.neighbourhood_code,
        },
        {
          key: "Buurtnaam",
          value: property.neighbourhood_name,
        },
        {
          key: "Gemeentecode",
          value: property.municipality_code,
        },
        {
          key: "Gemeentenaam",
          value: property.municipality_name,
        },
        {
          key: "Provincie",
          value: property.province,
        },
      ],
    },
  ];

  const reference = [
    { text: "Marktwaarde bepalen", path: "/avm" },
    {
      text: "Referenties opvragen",
      path: "/referentie",
    },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];

  return (
    <>
      <APIResult
        property={property}
        loading={loading}
        title={"Woning object gegevens"}
        street={property.street + " " + property.housenumber}
        postCode={splitAddress(property.postcode)}
        city={property.city}
        overview={overview}
        reference={reference}
        descSection={desc}
        buildingPhoto={buildingPhoto}
        result={result}
        path="https://docs.altum.ai/apis/object-data-api"
        clearResult={clearResults}
        modifyResult={modifyResults}
      />
    </>
  );
};

export default ObjectDataResult;
