import { FC, useState } from "react";
import styled from "styled-components";
import {
  ArrowDropdownCaretSortSelectArrow,
  ArrowUpPushupCaretSortSelectArrow,
} from "react-basicons";
import axios from "axios";
import { Form } from "./Form";
import { EmailSettings, FormField } from "../@types";
import { Colors, device, TextStyles } from "../styles/Theme";
import { Main, Title } from "../styles/styled/Common.styled";
import Switch from "./Switch";
import { useAppDispatch } from "../redux/hooks";
import { updateUserEmailSettings } from "../redux/actions/settingAction";

const SettingItem: FC<{
  title: string;
  value: boolean;
  field:
    | "error_429"
    | "error_403"
    | "error_500"
    | "error_422"
    | "analytics_email_usage"
    | "invoice_successful"
    | "credit_depleted";
  user_id: string;
}> = ({ title, value, field, user_id }) => {
  const dispatch = useAppDispatch();

  return (
    <SettingsItem>
      <SubTitle>{title}</SubTitle>
      <Switch
        check={value}
        onChange={() =>
          dispatch(
            updateUserEmailSettings({
              id: user_id,
              field,
              value: !value,
            }),
          )
        }
      />
    </SettingsItem>
  );
};

const Settings: FC<EmailSettings> = ({
  analytics_email_usage,
  invoice_successful,
  credit_depleted,
  error_403,
  error_500,
  error_429,
  error_422,
  user_id,
}) => {
  const [selected, setSelected] = useState<number | null>(null);
  const toggle = (i: number) => {
    setSelected(selected === i ? null : i);
  };

  const handleSubmit = async (data: Record<string, string>) => {
    try {
      await axios.post(`/api/v1/users/${Object.keys(data)[0]}`, data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const settings = [
    { title: "429 e-mail", field: "error_429" as const, value: error_429 },
    { title: "500 e-mail", field: "error_500" as const, value: error_500 },
    { title: "403 e-mail", field: "error_403" as const, value: error_403 },
    { title: "422 e-mail", field: "error_422" as const, value: error_422 },
    {
      title: "Analytics e-mailgebruik",
      field: "analytics_email_usage" as const,
      value: analytics_email_usage,
    },
    {
      title: "Factuur succesvolle e-mail",
      field: "invoice_successful" as const,
      value: invoice_successful,
    },
    {
      title: "E-mail met tegoed opgebruikt",
      field: "credit_depleted" as const,
      value: credit_depleted,
    },
  ];

  return (
    <>
      <Main>
        <Title>Email instellingen</Title>
        {settings.map((setting, i) => (
          <SettingItem key={i} {...setting} user_id={user_id} />
        ))}
      </Main>
      <Main id="settings">
        <Title>Wijzigen</Title>
        <hr />
        {data.map((item, i) => (
          <Accordion key={i}>
            <Column>
              <AccordionFlex onClick={() => toggle(i)}>
                <SettingsItem>
                  <SubTitle>{item[0].label}</SubTitle>
                  {selected === i ? (
                    <ArrowUpPushupCaretSortSelectArrow color="#707070" />
                  ) : (
                    <ArrowDropdownCaretSortSelectArrow color="#707070" />
                  )}
                </SettingsItem>
              </AccordionFlex>
            </Column>
            <Content className={selected === i ? "show" : ""}>
              <Form
                fields={item}
                initInput={{ [item[0].name]: "" }}
                handleSubmit={handleSubmit}
                cta="Wijzigen"
              />
            </Content>
          </Accordion>
        ))}

        <DeleteAccountSection />
      </Main>
    </>
  );
};

function DeleteAccountSection() {
  return (
    <Column>
      <SubTitle style={{ color: "red" }}>Account verwijderen</SubTitle>
      <Textstyle>
        Wil je het account verwijderen? Neem dan contact op via info@altum.ai
      </Textstyle>
    </Column>
  );
}

const data: FormField[][] = [
  [
    {
      label: "Bedrijfsnaam wijzigen",
      type: "text",
      name: "company",
    },
  ],
  [
    {
      label: "KvK nummer wijzigen",
      type: "text",
      name: "kvk",
    },
  ],
  [
    {
      label: "BTW nummer wijzigen",
      type: "text",
      name: "vat",
    },
  ],
];

export default Settings;

const Content = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);

  &.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;

const SettingsItem = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  padding: 10px 10px;
  border: 1px solid ${Colors.main.lightgrey};
  border-radius: 10px;
  align-items: center;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: ${Colors.main.black};

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

const Textstyle = styled.div`
  ${TextStyles.Bundler(TextStyles.Text.P)};
  color: ${Colors.main.darkgrey};
  max-width: 100%;
  word-break: break-word;
  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  border-radius: 4px;
  width: 100%;
  @media ${device.mobileL} {
    gap: 10px;
  }
`;

const AccordionFlex = styled.div`
  cursor: pointer;
`;

const Accordion = styled.div`
  .content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;
