import React from "react";
import { NavLink } from "react-router-dom";
import { Item } from "./Sidebar";
import { IconType } from "react-icons";
import { IconConfig } from "react-basicons/dist/types";

interface SidebarItemProps {
  name: string;
  path: string;
  Icon: IconType | React.FC<IconConfig>;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  name,
  path,
  Icon,
}) => (
  <Item>
    <NavLink to={path}>
      <div>
        <Icon size={20} />
      </div>
      <span>{name}</span>
    </NavLink>
  </Item>
);
