import { Select } from "flowbite-react";
import React, { ChangeEvent, ChangeEventHandler, FC } from "react";

type Props = {
  label?: string;
  name?: string;
  className?: string;
  options: (string | undefined)[];
  onChange?: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
};

const SelectInput: FC<Props> = ({
  options,
  label,
  name,
  className,
  onChange,
}) => {
  return (
    <Select
      className=""
      name={name}
      theme={{
        field: {
          select: {
            base: "focus:border-0 focus:ring-0 border-primary focus:outline-primary outline-primary border-gray-light w-full",
            colors: {
              gray: "gray-light",
              primary: "primary",
            },
          },
        },
      }}
      onChange={onChange}
    >
      {!!label && <option defaultChecked>{label}</option>}
      {options.map((option, index) => (
        <option key={index}>{option}</option>
      ))}
    </Select>
  );
};

export default SelectInput;
