import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import SolarScanForm from "./SolarScanForm";

const SolarScan = () => {
  const { loading, result } = useAppSelector((state) => state.solarscan);
  const solarResult = result as Array<{}>;
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {solarResult.length > 0 ? (
        <Redirect
          to={{
            pathname: "/solarscan-result",
          }}
        />
      ) : (
        <SolarScanForm />
      )}
    </>
  );
};

export default SolarScan;
