import { FormEvent, useEffect, useState } from "react";
import { ObjectGeometryField } from "./objectGeometryField";
import FormBody from "../FormBody";
import { ObjectGeometry } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";

import { postGeometry } from "../../../redux/actions/objectGeometryActions";

const ObjectGeometryForm = () => {
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });
  const { savedQueries } = useAppSelector((state) => state.objectGeometry);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
      houseaddition: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postGeometry({ formData: newFormData, apiKey }));
  };
  return (
    <FormBody
      title={"Objectgeometrie opvragen"}
      desc={
        "Gebruik objectgeometriegegevens als aanvulling op de Verduurzaming API voor verbeterde berekeningen door het ophalen van o.a. dakoppervlakken, oriëntatie en helling"
      }
      path={"https://docs.altum.ai/apis/object-geometrie-api"}
      img={ObjectGeometry}
      initialFields={ObjectGeometryField}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
      setFormValues={setHouseOptions}
      formValues={houseOptions}
    />
  );
};

export default ObjectGeometryForm;
