export const referenceFields = [
  {
    label: "Postcode*",
    name: "postcode",
    type: "text",
    placeholder: "1234AB",
    tooltip: "",
  },
  {
    label: "Huisnummer*",
    name: "housenumber",
    type: "text",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Huisnummer toevoeging",
    name: "houseaddition",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Gewenst Energielabel",
    name: "energylabel",
    type: "text",
    placeholder: "A",
    tooltip: "",
  },
  {
    label: "Strict zoeken energielabel",
    name: "strict_energylabel",
    type: "radio",
    placeholder: "",
    tooltip: "",
    options: [
      { label: "Niet strikt", value: "false" },
      { label: "strikt", value: "true" },
    ],
  },
  {
    label: "Waarderingsdatum",
    name: "validationdate",
    type: "text",
    placeholder: "20211230",
    tooltip: "",
  },
  {
    label: "Oppervlakte doelobject (m2)",
    name: "innersurfacearea",
    type: "number",
    placeholder: "160",
    tooltip: "",
  },
  {
    label: "Bouwjaar doelobject",
    name: "buildyear",
    type: "number",
    placeholder: "2001",
    tooltip: "",
  },
  {
    label: "Aantal referenties (vanaf 3 t/m 30)",
    name: "reference_number",
    type: "number",
    placeholder: "10",
    tooltip: "",
  },
  {
    label: "Woningtype",
    name: "housetype",
    type: "select",
    placeholder: "Vrijstaande woning",
    tooltip: "",
    options: [
      { value: "Vrijstaande woning", label: "Vrijstaande woning" },
      {
        value: "Vrijstaande doelgroepwoning",
        label: "Vrijstaande doelgroepwoning",
      },
      {
        value: "Vrijstaande recreatiewoning",
        label: "Vrijstaande recreatiewoning",
      },
      { value: "2 onder 1 kap woning", label: "2 onder 1 kap woning" },
      {
        value: "Geschakelde 2 onder 1 kapwoning",
        label: "Geschakelde 2 onder 1 kapwoning",
      },
      { value: "Geschakelde woning", label: "Geschakelde woning" },
      { value: "Tussen/rijwoning", label: "Tussen/rijwoning" },
      {
        value: "Tussen/rij doelgroepwoning",
        label: "Tussen/rij doelgroepwoning",
      },
      { value: "Hoekwoning", label: "Hoekwoning" },
      { value: "Eindwoning", label: "Eindwoning" },
      { value: "Galerijflat", label: "Galerijflat" },
      { value: "Portiekflat", label: "Portiekflat" },
      { value: "Corridorflat", label: "Corridorflat" },
      { value: "Maisonnette", label: "Maisonnette" },
      { value: "Benedenwoning", label: "Benedenwoning" },
      { value: "Benedenwoning", label: "Bovenwoning" },
      { value: "Portiekwoning", label: "Portiekwoning" },
    ],
  },
  {
    label: "Zoeken in dezelfde straat",
    name: "strict_street",
    type: "radio",
    placeholder: "",
    tooltip: "",
    options: [
      { label: "Niet strikt", value: "false" },
      { label: "strikt", value: "true" },
    ],
  },
  {
    label: "Zoeken in dezelfde buurt",
    name: "strict_buurt",
    type: "radio",
    placeholder: "",
    tooltip: "",
    options: [
      { label: "Niet strikt", value: "false" },
      { label: "strikt", value: "true" },
    ],
  },
  {
    label: "Zoeken in dezelfde wijk",
    name: "strict_wijk",
    type: "radio",
    placeholder: "",
    tooltip: "",
    options: [
      { label: "Niet strikt", value: "Niet strikt" },
      { label: "strikt", value: "strikt" },
    ],
  },
  {
    label: "Vergelijkbaarheid woningtype",
    name: "comparable_housetype",
    type: "select",
    placeholder: "Alle vergelijkingen mogelijk",
    tooltip: "",
    options: [
      {
        value: "Alle vergelijkingen mogelijk",
        label: "Alle vergelijkingen mogelijk",
      },
      {
        value: "Strict vergelijkbaar",
        label: "Strict vergelijkbaar",
      },
      {
        value: "Breder vergelijkbaar",
        label: "Breder vergelijkbaar",
      },
    ],
  },
  {
    label: "Vergelijkbaarheid woonoppervlakte",
    name: "comparable_innersurfacearea",
    type: "select",
    placeholder: "Alle mogelijke oppervlaktes",
    tooltip: "",
    options: [
      {
        value: "Alle mogelijke oppervlaktes",
        label: "Alle mogelijke oppervlaktes",
      },
      {
        value: "Maximaal 10% jaar verschil",
        label: "Maximaal 10% jaar verschil",
      },
      {
        value: "Maximaal 20% jaar verschil",
        label: "Maximaal 20% jaar verschil",
      },
      {
        value: "Maximaal 30% jaar verschil",
        label: "Maximaal 30% jaar verschil",
      },
    ],
  },
  {
    label: "Vergelijkbaarheid bouwperiode",
    name: "comparable_innersurfacearea",
    type: "select",
    placeholder: "Alle mogelijke oppervlaktes",
    tooltip: "",
    options: [
      {
        value: "Alle mogelijke bouwperiode",
        label: "Alle mogelijke bouwperiode",
      },
      {
        value: "Maximaal 10% jaar verschil",
        label: "Maximaal 10% jaar verschil",
      },
      {
        value: "Maximaal 20% jaar verschil",
        label: "Maximaal 20% jaar verschil",
      },
      {
        value: "Maximaal 30% jaar verschil",
        label: "Maximaal 30% jaar verschil",
      },
    ],
  },
  {
    label: "Vergelijkbaarheid afstand tot doelobject",
    name: "comparable_distance",
    type: "select",
    placeholder: "Alle vergelijkingen mogelijk",
    tooltip: "",
    options: [
      {
        value: "Alle vergelijkingen mogelijk",
        label: "Alle vergelijkingen mogelijk",
      },
      {
        value: "Strict vergelijkbaar",
        label: "Strict vergelijkbaar",
      },
      {
        value: "Breder vergelijkbaar",
        label: "Breder vergelijkbaar",
      },
    ],
  },
  {
    label: "Belang oppervlakte in weging",
    name: "weight_innersurfacearea",
    type: "number",
    placeholder: "0.2",
    tooltip: "",
  },
  {
    label: "Belang bouwperiode in weging (van 0 t/m 1)",
    name: "weight_buildyear",
    type: "number",
    placeholder: "0.2",
    tooltip: "",
  },
  {
    label: "Vergelijkbaarheid afstand tot doelobject(van 0 t/m 1)",
    name: "weight_transactiondate",
    type: "number",
    placeholder: "0.2",
    tooltip: "",
  },
  {
    label: "Belang afstand tot doelobject in weging(van 0 t/m 1)",
    name: "weight_distance",
    type: "number",
    placeholder: "0.2",
    tooltip: "",
  },
  {
    label: "Transactie historie in maanden (van 6 t/m 60)",
    name: "date_limit",
    type: "number",
    placeholder: "12",
    tooltip:
      "Geef aan tot hoever voorafgaand aande waarderingsdatum in maanden kan worden gezocht voor relevante transacties",
  },
  {
    label: "Vergelijkbare woningtypes",
    name: "housetype_to_search",
    type: "select",
    placeholder: "Selecteer woningtypes",
    tooltip: "",
    options: [
      { value: "Vrijstaande woning", label: "Vrijstaande woning" },
      {
        value: "Vrijstaande doelgroepwoning",
        label: "Vrijstaande doelgroepwoning",
      },
      {
        value: "Vrijstaande recreatiewoning",
        label: "Vrijstaande recreatiewoning",
      },
      { value: "2 onder 1 kap woning", label: "2 onder 1 kap woning" },
      {
        value: "Geschakelde 2 onder 1 kapwoning",
        label: "Geschakelde 2 onder 1 kapwoning",
      },
      { value: "Geschakelde woning", label: "Geschakelde woning" },
      { value: "Tussen/rijwoning", label: "Tussen/rijwoning" },
      {
        value: "Tussen/rij doelgroepwoning",
        label: "Tussen/rij doelgroepwoning",
      },
      { value: "Hoekwoning", label: "Hoekwoning" },
      { value: "Eindwoning", label: "Eindwoning" },
      { value: "Galerijflat", label: "Galerijflat" },
      { value: "Portiekflat", label: "Portiekflat" },
      { value: "Corridorflat", label: "Corridorflat" },
      { value: "Maisonnette", label: "Maisonnette" },
      { value: "Benedenwoning", label: "Benedenwoning" },
      { value: "Benedenwoning", label: "Bovenwoning" },
      { value: "Portiekwoning", label: "Portiekwoning" },
    ],
  },
];
