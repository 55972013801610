import styled from "styled-components";
import { TextStyles, Colors, device } from "../styles/Theme";
import React, { ReactNode } from "react";

function CookieBanner({
  color,
  body,
  children,
}: {
  color: string;
  body: any;
  children: ReactNode;
}) {
  return (
    <BottomText style={{ backgroundColor: color }}>
      <Text>{body}</Text>
      {children}
    </BottomText>
  );
}

export default CookieBanner;

const Text = styled.p`
  ${TextStyles.Bundler(TextStyles.Text.SmallText)}
  a {
    color: ${Colors.main.green};
    ${TextStyles.Bundler(TextStyles.Text.SmallText)}
  }
  @media ${device.laptop} {
    ${TextStyles.Bundler(TextStyles.Text.Pmedium)}
  }
`;

const BottomText = styled.div`
  background: ${Colors.main.lightGreen};
  color: ${Colors.main.darkgrey};
  width: 100%;
  text-align: center;
  border-radius: 16px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${device.laptop} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;
