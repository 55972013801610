import { FC } from "react";
import styled from "styled-components";
import { TextStyles, device } from "../styles/Theme";
import { GoLocation } from "react-icons/go";
import { FaAngleRight } from "react-icons/fa";
import convertToEuFormat from "../helpers/convertToEuFormat";
import formatDate from "../helpers/convertToDutchMonth";
import { convertNumberRange } from "../helpers/common";
import Text from "./Text";
import Subtitle from "./Subtitle";

interface SolutionCardProps {
  lat: string;
  lon: string;
  houseNumber: string;
  city: string;
  street: string;
  transactionPrice: string;
  innerSurfaceArea: number;
  transactionDate: string;
}
export const Card: FC<SolutionCardProps> = ({
  lat,
  lon,
  houseNumber,
  city,
  street,
  transactionPrice,
  innerSurfaceArea,
  transactionDate,
}) => {
  return (
    <Container>
      <ImageContainer
        src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${lat},${lon}&key=${process.env.REACT_APP_GOOGLE_STREETVIEW_APIKEY}`}
        alt="street-view"
      />

      <div className="flex items-center gap-3">
        <GoLocation />
        <span> {`${street} ${houseNumber}  ${city}`}</span>
      </div>
      <p className="api-card__description font-small flex gap-2">
        <Subtitle className="text-base">Woonoppervlakte:</Subtitle>{" "}
        {`${convertToEuFormat(innerSurfaceArea)} m2`}
      </p>
      <p className="api-card__description font-small flex gap-2">
        <Subtitle className="text-base">Transactie datum:</Subtitle>{" "}
        {`${formatDate(transactionDate)}`}
      </p>
      <p className="api-card__description font-small flex gap-2">
        <Subtitle className="text-base">Koopsom:</Subtitle>{" "}
        {`€ ${convertNumberRange(transactionPrice)}`}
      </p>
      <div className="arrow" style={{ marginLeft: "auto" }}>
        <FaAngleRight />
      </div>
    </Container>
  );
};

export const CardRental: FC<{
  houseNumber: string;
  city: string;
  rentalPrice: string;
  street: string;
  innerSurfaceArea: string;
}> = ({ houseNumber, city, street, rentalPrice, innerSurfaceArea }) => {
  return (
    <Container>
      <div className="flex items-center gap-3">
        <GoLocation />
        <Text> {`${street} ${houseNumber}  ${city}`}</Text>
      </div>
      <p className="api-card__description font-small flex gap-2">
        <Subtitle className="text-base">Woonoppervlakte:</Subtitle>{" "}
        {`${convertToEuFormat(innerSurfaceArea)} m2`}
      </p>

      <p className="api-card__description font-small flex gap-2">
        <Subtitle className="text-base">Huur:</Subtitle>{" "}
        {`€${convertToEuFormat(rentalPrice)} p/m`}
      </p>
      <div className="arrow" style={{ marginLeft: "auto" }}>
        <FaAngleRight />
      </div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding: 1rem;
  align-items: left;
  border: 1px solid rgba(48, 57, 105, 0.15);
  min-width: 300px;
`;

const ImageContainer = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  margin-bottom: 10px;
  .container {
    display: flex;
    flex-direction: row;
  }
`;
