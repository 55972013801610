import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../Loading";
import RebuildForm from "./RebuildForm";

const Rebuild = () => {
  const { loading, result } = useAppSelector((state) => state.rebuild);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/rebuild-result",
          }}
        />
      ) : (
        <RebuildForm />
      )}
    </>
  );
};

export default Rebuild;
