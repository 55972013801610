import { FormEvent, useEffect, useState } from "react";
import { AvmFields } from "./avmfields";
import FormBody from "../FormBody";
import { AVM } from "../../../assets/images/api/APIimages";
import { postAvmDetails } from "../../../redux/actions/avmActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { Helmet } from "react-helmet";

const AvmForm = () => {
  const sectionFields = [
    { title: "Welke woning wil je waarderen?", startIndex: 0, endIndex: 6 },
  ];
  const savedQueries = useAppSelector((state) => state.avm.savedQueries) as any;

  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const dispatch = useAppDispatch();

  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });

  useEffect(() => {
    const sliderCircle = document.querySelector(
      "#energylabel > div:last-child > div:last-child",
    ) as HTMLElement;
    const Track1 = document.querySelector(
      "#energylabel > div:first-child > div",
    );
    if (Track1) {
      Track1 && Track1.classList.add("energylabel-track");
    }
    if (sliderCircle) {
      sliderCircle.classList.add("form-slider");
      sliderCircle.style.backgroundColor = "rgba(0,166,82,255)";
      sliderCircle.textContent = savedQueries?.energylabel || "A";
    }
  }, [savedQueries?.energylabel]);
  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions | FormOptionsSustainability = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof (FormOptions | FormOptionsSustainability)] =
          value;
      }
    });
    apiKey && dispatch(postAvmDetails({ formData: newFormData, apiKey }));
  };

  return (
    <FormBody
      title={"Woningwaarde model"}
      desc={
        "Maak gebruik van de Woningwaarde API om de huidige marktwaarde van een huis te weten te komen. De API gebruikt gegevens uit openbare registers, die maandelijks worden bijgewerkt met nieuw geregistreerde verkoopprijzen."
      }
      path={"https://docs.altum.ai/apis/woningwaarde+-api"}
      img={AVM}
      sectionFields={sectionFields}
      initialFields={AvmFields}
      handleSubmit={handleFormSubmit}
      setFormValues={setHouseOptions}
      formValues={houseOptions}
      options="Overschrijf de standaard uitgangspunten voor een geoptimaliseerd resultaat"
    />
  );
};

export default AvmForm;
