import { FC } from "react";
import SignupRightSection from "./components/SignupRightSection";
import AuthLeftSection from "../components/AuthLeftSection";
import AuthImage from "../../../assets/images/login-image.png";

const Signup: FC = () => {
  return (
    <>
      <div className="flex h-screen">
        <AuthLeftSection image={AuthImage} />
        <SignupRightSection />
      </div>

      {/* <div className="copyright">
        <Text>© Altum AI B.V.</Text>
      </div> */}
    </>
  );
};
export default Signup;
