import {
  Table as FlTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "flowbite-react";
import { FC } from "react";

type Props = {
  headers: string[];
  tableItems?: { [key: string]: string | number | null | JSX.Element }[];
};

const Table: FC<Props> = ({ headers, tableItems }) => {
  return (
    <div className="overflow-x-auto">
      <FlTable>
        <TableHead>
          {headers.map((header, index) => (
            <TableHeadCell key={index}>{header}</TableHeadCell>
          ))}
        </TableHead>
        <TableBody className="divide-y">
          {tableItems?.map((item, index) => (
            <TableRow
              key={index}
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              {Object.entries(item).map(([key, value], idx) => (
                <TableCell
                  key={idx}
                  className="whitespace-nowrap font-medium text-gray-900"
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </FlTable>
    </div>
  );
};

export default Table;
