import React, { useState, useEffect } from "react";
import APIResult, { LightText } from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getStreetViewImage, splitAddress } from "../../../helpers/common";
import formatDate from "../../../helpers/convertToDutchMonth";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { useHistory, Redirect } from "react-router-dom";
import {
  clearAvmResults,
  modifyAvmQueries,
} from "../../../redux/actions/avmActions";
import DownloadAvmPdf from "../../AVM/AVMResultPDF";

const AvmResult = () => {
  const { result, loading } = useAppSelector((state) => state.avm);
  const property: any = result;
  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      // Get image from Streetview API
      const { latitude, longitude } = property;
      getStreetViewImage(latitude, longitude).then((url) =>
        setBuildingPhoto(url),
      );
    }
  }, [property]);

  const history = useHistory();
  const dispatch = useAppDispatch();

  //TODO: [ALTUMAI-1626] All the clearResult functions and modifyResult functions should have redirect back to the form. Eg avm will the history.push('/avm'), sustainability  will be history.push('/sustainability')

  const clearResults = () => {
    dispatch(clearAvmResults());
    history.push("/avm");
  };

  const modifyResults = () => {
    dispatch(modifyAvmQueries());
    history.push("/avm");
  };
  if (Object.keys(property).length === 0) {
    return <Redirect to="/avm" />;
  }
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: property.bag_id,
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `${property.houseaddition ? property.houseaddition : "-"}`,
    },
    {
      img: "",
      title: "Latitude",
      result: property.latitude,
    },
    {
      img: "",
      title: "Longitude",
      result: property.longitude,
    },
  ];

  const overview = [
    {
      title: "Resultaat",
      details: [
        {
          key: "Woningtype",
          value: property.house_type,
        },
        {
          key: "Waardebepaling",
          value: `€ ${convertToEuFormat(property.price_estimation)}`,
        },
        {
          key: "Bouwjaar",
          value: property.build_year,
        },

        {
          key: "Energielabel",
          value: property.energy_label,
        },
        {
          key: "Waarderingsdatum",
          value: formatDate(property.valuation_date),
        },
        {
          key: "Betrouwbaarheidspercentage",
          value: property.confidence,
        },
        {
          key: "Woonoppervlakte",
          value: `${convertToEuFormat(property.inner_surface_area)} m2`,
        },
        {
          key: "Perceeloppervlakte",
          value: `${convertToEuFormat(property.outer_surface_area)} m2`,
        },
        {
          key: "Inhoud",
          value: `${convertToEuFormat(property.volume)} m3`,
        },
      ],
    },
  ];

  const reference = [
    { text: "Referenties opvragen", path: "/referentie" },
    { text: "Kadaster transactie ophalen", path: "/transactie-api" },
    { text: "WOZ waarde opvragen", path: "/woz" },
  ];

  return (
    <>
      <APIResult
        property={property}
        loading={loading}
        title={"Woningwaarde indicatie - Altum AI"}
        street={
          property.street +
          " " +
          property.housenumber +
          " " +
          property.houseaddition
        }
        postCode={splitAddress(property.postcode)}
        city={property.city}
        overview={overview}
        reference={reference}
        descSection={desc}
        buildingPhoto={buildingPhoto}
        result={result}
        path="https://docs.altum.ai/v/english/apis/avm+-api"
        clearResult={clearResults}
        modifyResult={modifyResults}
        description={<AVMDesc property={property} />}
        PDF={DownloadAvmPdf}
      />
    </>
  );
};

const AVMDesc: React.FC<{ property: any }> = ({ property }) => {
  return (
    <LightText style={{ marginBottom: "20px", textAlign: "left" }}>
      Voor de woning uit <strong>{property.build_year}</strong>, met het type{" "}
      {property.house_type}, gelegen aan de {property.street}{" "}
      {property.housenumber} {property.houseaddition} {property.city} is de
      indicatieve marktwaarde{" "}
      <strong>€{convertToEuFormat(property.price_estimation)}</strong>{" "}
      vastgesteld op {formatDate(property.valuation_date)}.
    </LightText>
  );
};

export default AvmResult;
