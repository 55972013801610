import React, { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RebuildField } from "./RebuildData";
import { postRebuild } from "../../../redux/actions/rebuildAction";
import { rebuild } from "../../../assets/images/api/APIimages";

const RebuidForm = () => {
  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 6 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });
  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postRebuild({ formData: newFormData, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Herbouwwaarde API"}
        sectionFields={sectionFields}
        desc={
          "Berekening van de herbouwwaarde van woningen t.b.v. opstalverzekeringen."
        }
        img={rebuild}
        path={""}
        initialFields={RebuildField}
        handleSubmit={handleFormSubmit}
        setFormValues={setHouseOptions}
        formValues={houseOptions}
        options="Geef een waarderingsdatum op informatie over het object te vinden van vóór die datum"
      />
    </div>
  );
};

export default RebuidForm;
