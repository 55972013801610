import { Button } from "flowbite-react";
import { IoMdArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
const BackButton = () => {
  const history = useHistory();

  return (
    <Button onClick={() => history.goBack()} className=" self-start">
      <IoMdArrowBack className="h-6 w-6 text-black" />
    </Button>
  );
};

export default BackButton;
