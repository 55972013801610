import { useState } from "react";
import APIResult from "../APIResult";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  clearAutosuggestResults,
  modifyAutosuggestQueries,
} from "../../../redux/actions/autosuggestAction";

const AutoSuggestResult = () => {
  const { loading, result } = useAppSelector((state) => state.autosuggest);
  const property: any = result;

  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  const history = useHistory();
  const dispatch = useAppDispatch();
  if (Object.keys(property).length === 0 || property.length === 0) {
    return <Redirect to="/autosuggest" />;
  }
  const clearResults = () => {
    dispatch(clearAutosuggestResults());
    history.push("/autosuggest");
  };

  const modifyResults = () => {
    dispatch(modifyAutosuggestQueries());
    history.push("/autosuggest");
  };
  const formattedOutput =
    !!property &&
    property.map((item: any) => {
      const outputFormat = {
        title: "Auto suggest",
        details: [
          {
            key: "Bag Id",
            value: item.bagid,
          },
          {
            key: "House Address",
            value: item.houseaddress,
          },
          {
            key: "Post code",
            value: item.postcode,
          },
          {
            key: "House Number",
            value: item.housenumber,
          },
          {
            key: "Street",
            value: item.street,
          },
          {
            key: "City",
            value: item.city,
          },
          {
            key: "Province",
            value: item.province,
          },
        ],
      };

      return outputFormat;
    });
  const desc = [
    {
      img: "",
      title: "Bag ID",
      result: "",
    },
    {
      img: "",
      title: "Huisnummer toevoeging",
      result: `-`,
    },
  ];

  const reference = [
    { text: "Verduurzaming advies", path: "/sustainability" },
    { text: "Energielabel vaststellen", path: "/energy-label" },
    { text: "Marktwaarde bepalen", path: "/avm" },
  ];
  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Autosuggestie API"}
      street={property.HouseNumber + "" + property.Street}
      postCode={property.PostCode}
      city={property.City}
      overview={[...formattedOutput]}
      reference={reference}
      descSection={desc}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/auto-suggest-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
    />
  );
};

export default AutoSuggestResult;
