import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  AnalyticsState,
  getApisAnalytics,
} from "../../../redux/actions/apiUsage";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { findTopFiveApiUsage } from "../../../helpers/rankAPIByUsage";
import { filterAnylyticsData } from "../../../helpers/filterAnalyticData";
import DashboardContainer from "../components/Container";
import Header from "../components/Header";
import CardContainer from "../components/CardContainer";
import APILogs from "../startinpagina/components/APILogs";
import BarChartSkeleton from "./components/skeleton-loaders/BarChartSkeleton";
import PieChatSkeleton from "./components/skeleton-loaders/PieChatSkeleton";
import UsedAPIListSkeleton from "./components/skeleton-loaders/UsedAPIListSkeleton";
import apiColorPicker from "../../../helpers/apiColorPicker";
import apiNameConverter from "../../../helpers/apiNameConverter";
import Text from "../../../components/Text";
import { knowWhen } from "../../../helpers/time";
import { Log } from "../../../@types";

const Chart = React.lazy(() => import("./components/chart/Chart"));
const PieChartComponent = React.lazy(
  () => import("./components/chart/PieChatComponent"),
);

const filterDays: { [key: string]: string } = {
  "Afgelopen 30 dagen": "30",
  "Afgelopen week": "7",
  Gisteren: "2",
  Vandaag: "1",
};

function Analytics() {
  const [apiData, setApiData] = useState([]);
  const [apiStatus, setApiStatus] = useState<AnalyticsState["responseStatus"]>({
    "400": 0,
    "422": 0,
    "429": 0,
    "500": 0,
    success: 0,
  });
  const [days, setDays] = useState("30");
  const [period, setPeriod] = useState<string>("Afgelopen 30 dagen");
  const periods = ["Vandaag", "Gisteren", "Deze week", "Afgelopen 30 dagen"];
  const dispatch = useAppDispatch();
  const { apiUsage, auth } = useAppSelector((state) => state);
  const apiKey = auth.user?.api_key;
  const transactionApiKey = auth.user?.transaction_api_key;

  const pieData = useMemo(() => {
    return Object.entries(apiStatus).map(([key, value]) => ({
      name: key,
      value: value,
    }));
  }, [apiStatus]);

  const formattedLogs = useMemo(
    () =>
      apiUsage.usageLogs.logs?.map((log: Log) => ({
        ...log,
        api_name: apiNameConverter(log.api_name),
        requestTime: knowWhen(log.requestTime),
      })),
    [apiUsage.usageLogs.logs],
  );

  useEffect(() => {
    apiKey && dispatch(getApisAnalytics({ days, apiKey, transactionApiKey }));
  }, [apiKey, days, dispatch, transactionApiKey]);

  useEffect(() => {
    setApiData(apiUsage.analytics.statistics);
    setApiStatus(apiUsage.responseStatus);
  }, [apiUsage.responseStatus, apiUsage.analytics.statistics]);

  useEffect(() => {
    setDays(filterDays[period]);
  }, [period]);
  const called = filterAnylyticsData(apiData);
  const topApis = findTopFiveApiUsage(called);
  return (
    <>
      <DashboardContainer pageTitle={"Analytics - Altum AI"}>
        <Header
          title={"Analytics"}
          subtitle={
            "De Analytics omgeving laat het verbruik zien o.b.v. de API key over de afgelopen 30 dagen. Dit bevat zowel het verbruik via het platform als eigen integraties in een applicatie omdat die van dezelfde API key gebruik maken."
          }
        />
        <div className="flex flex-col gap-8">
          <CardContainer
            title={"Algehele gebruik"}
            period={period}
            setPeriod={setPeriod}
            periods={periods}
          >
            {apiUsage.analytics.loading ? (
              <BarChartSkeleton />
            ) : (
              <Suspense fallback={<BarChartSkeleton />}>
                <Chart data={apiData} />
              </Suspense>
            )}
          </CardContainer>
          <div className="flex gap-8 flex-col-reverse lg:flex-row">
            <CardContainer
              title={"API respons"}
              period={period}
              setPeriod={setPeriod}
              periods={periods}
            >
              {apiUsage.analytics.loading ? (
                <PieChatSkeleton />
              ) : (
                <Suspense fallback={<PieChatSkeleton />}>
                  <PieChartComponent data={pieData} />
                </Suspense>
              )}
            </CardContainer>
            <CardContainer
              title={"Meest gebruikte API's"}
              period={period}
              setPeriod={setPeriod}
              periods={periods}
            >
              <div className="flex gap-4 flex-col p-4">
                {apiUsage.analytics.loading ? (
                  <UsedAPIListSkeleton />
                ) : (
                  topApis.map((apiData: any) => (
                    <div className="flex justify-between">
                      <div className="flex gap-4 items-center">
                        <div
                          className={`w-2 h-2 rounded-full`}
                          style={{
                            backgroundColor: apiColorPicker(apiData.apiName),
                          }}
                        ></div>
                        <Text>{apiNameConverter(apiData.apiName)}</Text>
                      </div>
                      <Text>{apiData.usage}</Text>
                    </div>
                  ))
                )}
              </div>
            </CardContainer>
          </div>
          <APILogs logs={formattedLogs} />
        </div>
      </DashboardContainer>
    </>
  );
}

export default Analytics;
