import { TextInput } from "flowbite-react";
import { ChangeEvent, FC } from "react";
import { IconType } from "react-icons";

interface InputProps {
  type?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  icon?: IconType;
  size?: string;
  value?: string;
  message?: string | null;
  reference?: React.RefObject<HTMLInputElement>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
  type,
  placeholder,
  className,
  icon,
  size = "lg",
  name,
  message,
  reference,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <TextInput
      ref={reference}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      helperText={message}
      icon={icon}
      color={message ? "failure" : "white"}
      onChange={onChange}
      onBlur={onBlur}
      sizing={size}
      shadow={false}
      theme={{
        field: {
          input: {
            base: "focus:border-0 focus:ring-0 border-primary focus:outline-primary outline-primary border-gray-light w-full",
            colors: {
              gray: "gray-light",
              primary: "primary",
            },
          },
        },
      }}
      className={`w-full ${className || ""}`}
    />
  );
};

export default Input;
