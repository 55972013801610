import React, { ChangeEvent, FC, useCallback, useState, memo } from "react";
import Subtitle from "../../../../../components/Subtitle";
import Text from "../../../../../components/Text";
import { TbExternalLink } from "react-icons/tb";
import { Checkbox } from "flowbite-react";
import Modal from "../../../../../components/Modal";
import { useFormContext } from "../../components/FormContext";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { tryParseInt } from "../../../../../helpers/tryParseInt";

type Props = {
  image: string;
  title: string;
  subtitle: string;
  description: string;
  inputs: any[];
};

const MeasureInput: FC<Props> = ({
  title,
  subtitle,
  image,
  inputs,
  description,
}) => {
  const [trigger, setTrigger] = useState(false);
  const { formValues, setFormValues } = useFormContext();

  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (isChecked) {
          setTrigger(true);
        } else {
          delete updatedValues[inputs[0].name || inputs[1]?.name];
        }
        return updatedValues;
      });
    },
    [inputs, setFormValues],
  );

  return (
    <>
      <div className="flex shadow-[0px_2px_8px_0px_#00000026] rounded-md px-2 py-3 cursor-pointer items-center gap-2 w-full md:min-w-[310px] h-[115px]">
        <img src={image} alt={title} className="w-16 h-16" />
        <div>
          <Subtitle className="text-base">{title}</Subtitle>
          <Text>{subtitle}</Text>
        </div>
        <Checkbox
          checked={
            inputs[0].name in formValues || inputs[1]?.name in formValues
          }
          onChange={handleCheck}
          className="focus:ring-primary dark:focus:ring-primary text-primary border-gray-dark rounded-full"
        />
      </div>
      <Modal trigger={trigger} setTrigger={setTrigger}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0">
          <div className="flex flex-col justify-between md:border-r-2 pr-4 gap-4 md:gap-8">
            <img src={image} alt={title} className="w-32 h-32" />
            <div className="ml-6">
              <Subtitle className="text-base">{title}</Subtitle>
              <Text>{description}</Text>
            </div>
            <a
              className="flex gap-2 ml-6 text-primary"
              href="https://docs.altum.ai/apis/sustainability-api/measures"
              target="_blank"
              rel="noreferrer"
            >
              <Text>Meer details </Text>
              <TbExternalLink />
            </a>
          </div>
          <div className="flex flex-col gap-8 ml-4">
            {inputs.map(({ name, type, options, label }) => (
              <ReturnInputField
                name={name}
                type={type}
                options={options}
                label={label}
                stack
                value={formValues[name] || ""}
                onChange={(value) => {
                  if (typeof value === "object") {
                    const event = value as React.ChangeEvent<
                      HTMLInputElement | HTMLSelectElement
                    >;
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      [name]: event.target.value,
                    }));
                  } else {
                    const [isInt, parsedValue] = tryParseInt(value);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      [name]: isInt ? parsedValue : value,
                    }));
                  }
                }}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(MeasureInput);
