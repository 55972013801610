import { useState, FormEvent, useEffect } from "react";
import FormBody from "../FormBody";
import { rentalReference } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { rentalReferenceFields } from "./rentalReferenceFields";
import { postRentalReference } from "../../../redux/actions/rentalReferenceAction";

const RentalReferenceForm = () => {
  const sectionFields = [
    { title: "Adres", startIndex: 0, endIndex: 2 },
    { title: "Andere opties", startIndex: 3, endIndex: 24 },
  ];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });
  const { savedQueries } = useAppSelector((state) => state.reference);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);

  const dispatch = useAppDispatch();

  const handleReferenceFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof FormOptions] = value;
      }
    });
    apiKey && dispatch(postRentalReference({ formData: newFormData, apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Huurreferentie API"}
        sectionFields={sectionFields}
        desc={
          "De interactieve huurreferentie API geeft de taxateur volledige flexibiliteit bij het vinden van huurreferentieobjecten op basis van meerdere variabelen en berekent de marktwaarde huur voor de woning."
        }
        img={rentalReference}
        path={""}
        initialFields={rentalReferenceFields}
        handleSubmit={handleReferenceFormSubmit}
        setFormValues={setHouseOptions}
        formValues={houseOptions}
        options="Gebruik de interactieve waardes om een verfijning uit te voeren op de zoekopdracht"
      />
    </div>
  );
};

export default RentalReferenceForm;
