import styled from "styled-components";
import { Colors, TextStyles, device } from "../Theme";

export const Main = styled.div`
  /* background-color: ${Colors.main.white}; */
  /* opaciy: 0.5; */
  padding: 2rem;
  /* border: 1px solid #e5e7eb; */
  border-radius: 0.5rem;
  /* box-shadow: 0px 2px 35px rgba(5, 27, 105, 0.06); */
  width: 100%;
  position: relative;
  align-items: left;
  margin-bottom: 1rem;

  @media ${device.mobileL} {
    padding: 10px;
  }
`;
export const Title = styled.div`
  ${TextStyles.Bundler(TextStyles.Subtitle.H4)};
  color: ${Colors.main.black};

  margin: 0;
  opacity: 80%;
`;

export const Heading = styled.div`
  ${TextStyles.Bundler(TextStyles.Subtitle.H3)};
  font-weight: 600;
  color: ${Colors.main.black};
  margin-bottom: 32px;
  padding: 2rem;
  opacity: 80%;

  @media ${device.mobileL} {
    margin: 0 18px;
  }
`;
