import { FormEvent, useEffect, useState } from "react";
import { EnergyLabelField } from "./energyLabelField";
import FormBody from "../FormBody";
import { EnergyLabel } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { postEnergyLabel } from "../../../redux/actions/energyLabelActions";

const EnergyLabelForm = () => {
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    post_code: "",
    house_number: "",
  });
  const { savedQueries } = useAppSelector((state) => state.energyLabel);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptionsSustainability),
      });
    }
  }, [savedQueries]);
  const sectionFields = [{ title: "", startIndex: 0, endIndex: 2 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const dispatch = useAppDispatch();

  const handleEnergyLabelFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedData: FormOptionsSustainability = {
      post_code: "",
      house_number: "",
    };
    houseOptions.post_code =
      houseOptions.post_code &&
      houseOptions.post_code.split("").join("").toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        updatedData[key] = value;
      }
    });

    apiKey && dispatch(postEnergyLabel({ formData: updatedData, apiKey }));
  };

  // const handleChange = (e: any) => {
  //   setHouseOptions({ ...house, [e.target.name]: undefined });
  // };

  return (
    <FormBody
      title={"NTA 8800 Energielabel API bepalen"}
      desc={
        "Bepaal het geschatte huidige of potentiële energielabel volgens de NTA 8800 norm op basis van de informatie over de huidige of verwachte situatie van het pand."
      }
      path={"https://docs.altum.ai/apis/energielabel-api"}
      img={EnergyLabel}
      initialFields={EnergyLabelField}
      sectionFields={sectionFields}
      handleSubmit={handleEnergyLabelFormSubmit}
      setFormValues={setHouseOptions}
      formValues={houseOptions}
      options="Overschrijf de standaard uitgangspunten voor een geoptimaliseerd resultaat"
    />
  );
};

export default EnergyLabelForm;
