import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import MoveForm from "./MoveForm";
import Loading from "../../Loading";

const Move = () => {
  const { loading, result } = useAppSelector((state) => state.moveData);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/verhuisdata-result",
          }}
        />
      ) : (
        <MoveForm />
      )}
    </>
  );
};

export default Move;
