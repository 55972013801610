import { useState, ReactNode, useEffect } from "react";
import DashboardContainer from "../../../../Dashboard/components/Container";
import Header from "../../../../Dashboard/components/Header";
import ProgressSection from "./ProgressSection";
import { useFormContext } from "../../components/FormContext";
import { Ecovalue } from "../../../../../assets/images/api/APIimages";
import ImageContainer from "../../components/ImageContainer";
import JsonToggle from "../../components/JsonToggle";
import JsonViewer from "./JsonViewer";
import { useAppSelector } from "../../../../../redux/hooks";
import { ExplorOtherApis } from "./Result";
import ImageSkeleton from "../../components/ImageSkeleton";

const Sustainability: React.FC<{ children: ReactNode; page: number }> = ({
  children,
  page,
}) => {
  const { formValues, buildingPhoto, houseAddress, fetchAddressData } =
    useFormContext();
  const { result, loading } = useAppSelector((state) => state.sustainability);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, fetchAddressData, buildingPhoto.length]);

  return (
    <DashboardContainer pageTitle="Verduurzamingsadvies genereren - Altum AI">
      <Header
        title="Verduurzamingsadvies genereren"
        subtitle="Genereer een compleet verduurzaming advies o.b.v. de NTA 8800 norm met een accurate inschatting van het energielabel, maatregelen, investeringskosten en CO2-reductie."
      />
      <JsonToggle toggle={toggle} setToggle={setToggle} />
      <div className="flex flex-col gap-16 md:gap-8 max-w-[1100px] pt-4 md:pt-4 relative">
        {page > 1 && buildingPhoto && !toggle && (
          <ImageContainer image={buildingPhoto} address={houseAddress} />
        )}
        {((page > 1 && !buildingPhoto && !toggle) || loading) && (
          <ImageSkeleton />
        )}

        <div className="flex flex-col-reverse gap-1 lg:gap-8 lg:flex-row">
          {toggle ? (
            <JsonViewer page={page} formValues={formValues} result={result} />
          ) : (
            <div className="flex flex-col w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl max-w-[800px] md:max-h-[830px] p-3 lg:p-8 gap-8">
              {children}
            </div>
          )}

          <ProgressSection page={page} image={Ecovalue} />
        </div>
        {page === 4 && <ExplorOtherApis />}
      </div>
    </DashboardContainer>
  );
};

export default Sustainability;
