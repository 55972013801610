import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import {
  FormContextType,
  FormOptions,
  FormOptionsSustainability,
} from "../../../../@types";
import { useValidation } from "../../../../hooks/useValidator";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../redux/hooks";
import axios from "axios";

const FormContext = createContext<FormContextType | undefined>(undefined);

const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formValues, setFormValues] = useState<
    FormOptions | FormOptionsSustainability
  >({});
  const [buildingPhoto, setBuildingPhoto] = useState("");
  const [houseAddress, setHouseAddress] = useState("");
  const { errors, validate } = useValidation();
  const { result } = useAppSelector((state) => state.sustainability);

  const postalAddress = useMemo(() => {
    if (Object.keys(formValues).length > 0) {
      return `${formValues.post_code}-${formValues.house_number}-${
        formValues.addition ? formValues.addition : ""
      }`;
    } else if (Object.keys(result).length > 0) {
      const propeties = result as any;
      return `${propeties?.address?.post_code}-${
        propeties.address?.house_number
      }-${propeties.address.addition ? propeties.address.addition : ""}`;
    } else {
      return "";
    }
  }, [formValues, result]);

  const fetchAddressData = useCallback(async () => {
    try {
      const { data } = await axios.post<any>("/api/v1/mopsus/address-data", {
        house_address: postalAddress,
      });
      const { imageBuffer, houseAddress } = data.data;
      const blob = new Blob([new Uint8Array(imageBuffer.data)], {
        type: "image/jpeg",
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setBuildingPhoto(imageUrl);
        setHouseAddress(houseAddress);
      };
      reader.readAsDataURL(blob);
    } catch (err: any) {
      toast.error("Could not get house address image");
    }
  }, [postalAddress, setBuildingPhoto, setHouseAddress]);

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        errors,
        validate,
        buildingPhoto,
        setBuildingPhoto,
        houseAddress,
        setHouseAddress,
        fetchAddressData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export { FormProvider, useFormContext };
