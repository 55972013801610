import React, { FormEvent, useEffect, useState } from "react";
import FormBody from "../FormBody";
import { autosuggest } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { autoSuggestField } from "./autoSuggestField";
import { postAutosuggest } from "../../../redux/actions/autosuggestAction";

const AutoSuggestForm = () => {
  const sectionFields = [{ title: "Zoekopdracht", startIndex: 0, endIndex: 3 }];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const [query, setQuery] = useState<FormOptions | FormOptionsSustainability>(
    {},
  );

  const { savedQueries } = useAppSelector((state) => state.objectData);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setQuery({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    apiKey && dispatch(postAutosuggest({ formData: query, apiKey: apiKey }));
  };

  return (
    <div>
      <FormBody
        title={"Autosuggestie API"}
        sectionFields={sectionFields}
        desc={
          "Vraag de Autosuggestie API voor een Nederlandse woning aan om een automatische suggestie te krijgen."
        }
        img={autosuggest}
        path={"https://docs.altum.ai/apis/zonnepanelen-dakenscan-api"}
        initialFields={autoSuggestField}
        handleSubmit={handleFormSubmit}
        setFormValues={setQuery}
        formValues={query}
        options="Geef een waarderingsdatum op informatie over het object te vinden van vóór die datum"
      />
    </div>
  );
};

export default AutoSuggestForm;
