import React from "react";
import { Redirect } from "react-router";
import { useAppSelector } from "../../../redux/hooks";
import AvmForm from "./AvmForm";
import Loading from "../../Loading";

const Avm = () => {
  const { result, loading } = useAppSelector((state) => state.avm);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(result).length > 0 ? (
        <Redirect
          to={{
            pathname: "/avm-result",
          }}
        />
      ) : (
        <AvmForm />
      )}
    </>
  );
};

export default Avm;
