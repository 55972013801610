import React from "react";
import Result, { ResultSummary } from "../components/Result";
import ResultSkeleton from "../components/ResultSkeleton";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import { Redirect, useHistory } from "react-router-dom";
import RedoButton from "../../components/RedoButtons";
import {
  clearSustainabilityResults,
  modifySustainabilityQueries,
} from "../../../../../redux/actions/sustainabilityActions";

type Props = {};

const SustainabilityResult = (props: Props) => {
  const { result, loading } = useAppSelector((state) => state.sustainability);
  const { buildingPhoto } = useFormContext();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearSustainabilityResults());
    history.push("/sustainability");
  };

  const modifyResults = () => {
    dispatch(modifySustainabilityQueries());
    history.push("/sustainability");
  };

  if (Object.keys(result).length === 0 && !loading) {
    return (
      <Redirect
        to={{
          pathname: "/sustainability",
        }}
      />
    );
  }
  return (
    <>
      {loading ? (
        <ResultSkeleton />
      ) : (
        <>
          <ResultSummary property={result} buildingPhoto={buildingPhoto} />
          <Result property={result} buildingPhoto={buildingPhoto} />
          <RedoButton modify={modifyResults} clear={clearResults} />
        </>
      )}
    </>
  );
};

export default SustainabilityResult;
