import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Img } from "../styles/styled";
import Settings from "./Settings";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { device, Colors, TextStyles } from "../styles/Theme";
import { Heading, Main, Title } from "../styles/styled/Common.styled";
import { getAllUserEmailSettings } from "../redux/actions/settingAction";

function Account() {
  const [vatId, setVatId] = useState<any[]>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { emailSettings } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  const getVatId = async () => {
    try {
      const res = await axios.get<any>("/api/v1/users/vat");
      setVatId(res.data.taxIds.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVatId();
    user && dispatch(getAllUserEmailSettings(user?.user_id));
  }, [emailSettings, user, dispatch]);

  return (
    <>
      <Helmet>
        <title>Account - Altum AI</title>
      </Helmet>
      <Heading>Account</Heading>
      <Main>
        <Title>Account overzicht</Title>
        <hr />
        <div style={{ display: "flex", alignItems: "center" }}>
          <ImageContainer>
            <Img
              width="100%"
              style={{ borderRadius: "50%" }}
              src={`https://ui-avatars.com/api/?name=${
                user?.company || user?.first_name || user?.email
              }`}
            />
          </ImageContainer>
          <TextFlex style={{}}>
            <div>
              <Textstyle>
                {user?.company.toUpperCase() ||
                  `${user?.first_name.toUpperCase()} ${user?.last_name.toUpperCase()}`}
              </Textstyle>
              <Textstyle
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  fontStyle: "italic",
                }}
              >
                {user?.email}
              </Textstyle>
              <InnerTextFlex>
                <Textstyle>{"KVK nummer"}</Textstyle>
                <Textstyle
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  {user?.kvk}
                </Textstyle>
              </InnerTextFlex>
              <InnerTextFlex>
                <Textstyle>{"BTW nummer"}</Textstyle>
                <Textstyle
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  {vatId[0]?.value}
                </Textstyle>
              </InnerTextFlex>
            </div>
          </TextFlex>
        </div>

        {emailSettings && <Settings {...emailSettings} />}
      </Main>
    </>
  );
}

export default Account;

const Textstyle = styled.div`
  ${TextStyles.Bundler(TextStyles.Heading.H2)};
  color: ${Colors.main.black};
  max-width: 100%;
  word-break: break-word;
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const TextFlex = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  @media ${device.mobileL} {
    flex-direction: column;
  }
  margin-left: 2rem;
  margin-bottom: 2rem;
`;
const InnerTextFlex = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-item: center;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 300px;
  border-radius: 50%;
  margin: 1rem 0;
`;
