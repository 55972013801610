import axios from "axios";
import convertToEuFormat from "./convertToEuFormat";
import { FormOptions, FormOptionsSustainability } from "../@types";

export const splitAddress = (str: string): string => {
  const matches = str.match(/[a-z]+|[^a-z]+/gi);
  return matches ? matches.join(" ") : "";
};

export const rearrangeDate = (date: string): string => {
  const dates = date.split("-");
  const newDate = `${dates[2]}-${dates[1]}-${dates[0]}`;
  return newDate;
};

export async function getStreetViewImage(Latitude: number, Longitude: number) {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/streetview?size=${600}x${200}&location=${Latitude},${Longitude}&key=${
      process.env.REACT_APP_GOOGLE_STREETVIEW_APIKEY
    }`,
    { responseType: "blob" },
  );
  // const imageNode = document.getElementById("map") as HTMLImageElement
  const imgUrl = URL.createObjectURL(response.data as Blob);
  // imageNode.src = imgUrl
  return imgUrl;
}

export const convertNumberRange = (range: string) => {
  const numbers = range.split("-");

  const newRange = `${convertToEuFormat(
    parseInt(numbers[0]),
  )}-${convertToEuFormat(parseInt(numbers[1]))}`;
  return newRange;
};

export const handleTargetLabel = (val: string | undefined) => {
  switch (val) {
    case "A++++":
      return 10;
    case "A+++":
      return 9;
    case "A++":
      return 8;
    case "A+":
      return 7;
    case "A":
      return 6;
    case "B":
      return 5;
    case "C":
      return 4;
    case "D":
      return 3;
    case "E":
      return 2;
    case "F":
      return 1;
    case "G":
      return 0;
    default:
      return 6;
  }
};

interface LabelData {
  backgroundColor: string;
  text: string;
}

const labelMapping: Record<number, LabelData> = {
  10: { backgroundColor: "rgba(0,166,82,255)", text: "A++++" },
  9: { backgroundColor: "rgba(0,166,82,255)", text: "A+++" },
  8: { backgroundColor: "rgba(0,166,82,255)", text: "A++" },
  7: { backgroundColor: "rgba(0,166,82,255)", text: "A+" },
  6: { backgroundColor: "rgba(0,166,82,255)", text: "A" },
  5: { backgroundColor: "rgba(138,199,62,255)", text: "B" },
  4: { backgroundColor: "rgba(189,214,48,255)", text: "C" },
  3: { backgroundColor: "rgba(220,179,39,255)", text: "D" },
  2: { backgroundColor: "rgba(247,148,29,255)", text: "E" },
  1: { backgroundColor: "rgba(241,90,43,255)", text: "F" },
  0: { backgroundColor: "rgba(238,28,37,255)", text: "G" },
};

export const getBackgroundColor = (text: string): string | undefined => {
  const foundEntry = Object.values(labelMapping).find(
    (entry) => entry.text === text,
  );
  return foundEntry ? foundEntry.backgroundColor : undefined;
};

export const target_labelSliderChange = (
  e: number,
  name?: string,
  setAddressFields?: (value: FormOptions | FormOptionsSustainability) => void,
) => {
  const slider = document.querySelector(`.${name}-track`);
  // const sliderCircle1 = document.querySelector(
  //   "#label-slider > div:first-child",
  // )
  const sliderCircle = document.querySelector(
    `#${name} > div:last-child > div:last-child`,
  );
  if (
    !slider ||
    !(slider instanceof HTMLElement) ||
    !sliderCircle ||
    !(sliderCircle instanceof HTMLElement)
  ) {
    return;
  }
  const { backgroundColor, text } = labelMapping[e];
  slider.style.backgroundColor = backgroundColor;
  // sliderCircle1.style.backgroundColor = backgroundColor;
  sliderCircle.style.backgroundColor = backgroundColor;
  sliderCircle.textContent = text;

  setAddressFields &&
    name &&
    setAddressFields((prevState: FormOptionsSustainability) => ({
      ...prevState,
      [name]: text,
    }));
};

export const stringToBoolean = (stringValue: string | number) => {
  switch (stringValue) {
    case "true":
      return true;

    case "false":
      return false;

    default:
      return stringValue;
  }
};
