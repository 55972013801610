import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getStreetViewImage, splitAddress } from "../../../helpers/common";
import APIResult from "../APIResult";
import convertToEuFormat from "../../../helpers/convertToEuFormat";
import { Redirect, useHistory } from "react-router-dom";
import {
  clearLocationDataResults,
  modifyLocationDataQueries,
} from "../../../redux/actions/locationDataActions";

const LocationDataResult = () => {
  const { result, loading } = useAppSelector((state) => state.locationData);
  const property: any = result;
  const [buildingPhoto, setBuildingPhoto] = useState<string>("");

  useEffect(() => {
    if (Object.keys(property).length > 0) {
      // Get image from Streetview API
      const { latitude, longitude } = property.bag;
      getStreetViewImage(latitude, longitude).then((url) =>
        setBuildingPhoto(url),
      );
    }
  }, [property]);

  const history = useHistory();
  const dispatch = useAppDispatch();
  if (Object.keys(property).length === 0) {
    return <Redirect to="/location-data" />;
  }
  const clearResults = () => {
    dispatch(clearLocationDataResults());
    history.push("/location-data");
  };

  const modifyResults = () => {
    dispatch(modifyLocationDataQueries());
    history.push("/location-data");
  };

  const overview = [
    {
      title: "Buurtgegevens",
      details: [
        {
          key: "Woonplaats",
          value: property.locality.city,
        },

        {
          key: "Totaal aantal inwoners",
          value: convertToEuFormat(
            property.locality.neighborhood_data.inhabitants.total,
          ),
        },
        {
          key: "Mannen",
          value: convertToEuFormat(
            property.locality.neighborhood_data.inhabitants.men,
          ),
        },
        {
          key: "Dames",
          value: convertToEuFormat(
            property.locality.neighborhood_data.inhabitants.women,
          ),
        },
        {
          key: "Leeftijdsgroep (0-14)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.age["0_14"],
          ),
        },
        {
          key: "Leeftijdsgroep (15-24)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.age["15_24"],
          ),
        },
        {
          key: "Leeftijdsgroep (25-44)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.age["25_44"],
          ),
        },
        {
          key: "Leeftijdsgroep (45-64)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.age["45_64"],
          ),
        },
        {
          key: "Leeftijdsgroep (65+)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.age["65_plus"],
          ),
        },
        {
          key: "Samenstelling huishouden (totaal)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.household_composition.total,
          ),
        },
        {
          key: "Huishoudelijke samenstelling (alleenstaand)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.household_composition.single,
          ),
        },
        {
          key: "Huishoudelijke samenstelling (zonder kinderen)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.household_composition
              .without_kids,
          ),
        },
        {
          key: "Huishoudelijke samenstelling (met kinderen)",
          value: convertToEuFormat(
            property.locality.neighborhood_data.household_composition.with_kids,
          ),
        },
        {
          key: "Gemiddelde huishoudengrootte",
          value: convertToEuFormat(
            property.locality.neighborhood_data.household_composition
              .average_household_size,
          ),
        },
      ],
    },
    {
      title: "Bag",
      details: [
        {
          key: "Postcode",
          value: splitAddress(property.bag?.postcode || ""),
        },
        {
          key: "Huisnummer",
          value: property.bag?.housenumber,
        },
        {
          key: "Huisnummer toevoeging",
          value: property.bag?.houseaddition,
        },
        {
          key: "Bouwjaar",
          value: property.bag?.build_year,
        },
        {
          key: "Nummer ID",
          value: property.bag?.num_id,
        },
        {
          key: "Openbare ruimte ID",
          value: property.bag?.opr_id,
        },
        {
          key: "Woonoppervlakte",
          value: `${convertToEuFormat(property.bag?.inner_surface_area)} m2`,
        },
        {
          key: "Perceeloppervlakte",
          value: `${convertToEuFormat(property.bag?.outer_surface_area)} m2`,
        },
        {
          key: "Object Status",
          value: property.bag?.object_status,
        },
        {
          key: "Type object",
          value: property.bag?.type_adressable_object,
        },
        {
          key: "Woonplaats status",
          value: property.bag?.city_status,
        },
        {
          key: "Buurtcode",
          value: property.bag?.neighborhood_code,
        },
        {
          key: "Woonplaats ID",
          value: property.bag?.wpl_id,
        },
        {
          key: "Sectieletter",
          value: property.bag?.section_letter,
        },
        {
          key: "Publieke plaats status",
          value: property.bag?.public_space_status,
        },
        {
          key: "Gebouw status",
          value: property.bag?.building_status,
        },
        {
          key: "Pand ID",
          value: property.bag?.pnd_id,
        },
        {
          key: "Indicatie status",
          value: property.bag?.indication_status,
        },
        {
          key: "Wijkcode",
          value: convertToEuFormat(property.bag?.district_code),
        },
        {
          key: "Perceelnummer",
          value: convertToEuFormat(property.bag?.parcel_number),
        },
        {
          key: "Tuin oriëntatie",
          value: property.bag?.garden_orientation_cat,
        },
        {
          key: "Tuin oriëntatie ID",
          value: convertToEuFormat(property.bag?.garden_orientation_num),
        },
        {
          key: "Gemeente code",
          value: property.bag?.municipality_code,
        },
        {
          key: "Perceel rotatie nummer",
          value: convertToEuFormat(property.bag?.parcel_number_rotation),
        },
      ],
    },
    {
      title: "Voorzieningen",
      details: [
        {
          key: "Treinstation",
          value: `${convertToEuFormat(property.amenities.railway_station)}m`,
        },
        {
          key: "School",
          value: `${convertToEuFormat(property.amenities.school)}m`,
        },
        {
          key: "Winkelcentrum",
          value: `${convertToEuFormat(property.amenities.shopping_mall)}m`,
        },
        {
          key: "Oprit snelweg",
          value: `${convertToEuFormat(property.amenities.highway_entry)}m`,
        },
        {
          key: "Politiebureau",
          value: `${convertToEuFormat(property.amenities.police_station)}m`,
        },
        {
          key: "Gemakswinkel",
          value: `${convertToEuFormat(property.amenities.convenience_store)}m`,
        },
        {
          key: "Bushalte",
          value: `${convertToEuFormat(property.amenities.bus_stop)}m`,
        },
        {
          key: "Ziekenhuis",
          value: `${convertToEuFormat(property.amenities.hospital)}m`,
        },
      ],
    },
    {
      title: "Koop en verkoopdata",
      details: [
        {
          key: "Gemiddelde prijs per vierkante meter",
          value: `${convertToEuFormat(
            property.locality.postcode_data.average_price_per_square_meter,
          )}m`,
        },
        {
          key: "Gemiddelde transactieprijs",
          value: `€${convertToEuFormat(
            property.locality.postcode_data.average_transaction_price,
          )}`,
        },
        {
          key: "Transacties afgelopen 12 maanden",
          value: `€${convertToEuFormat(
            property.locality.postcode_data.transactions_past_12_months,
          )}`,
        },
        {
          key: "Bevolkingsdichtheid per vierkante kilometer",
          value: `${convertToEuFormat(
            property.locality.postcode_data.population_density_km2,
          )}`,
        },
        {
          key: "Gemiddelde verkoopduur in dagen",
          value: `${convertToEuFormat(
            property.locality.postcode_data.average_for_sale_duration ?? "-",
          )}`,
        },
        {
          key: "Gemiddelde vraagprijs",
          value: `${convertToEuFormat(
            property.locality.postcode_data.average_asking_price,
          )}`,
        },
      ],
    },
  ];

  const reference = [{ text: "Object data opvragen", path: "/objectdata" }];

  return (
    <APIResult
      property={property}
      loading={loading}
      title={"Locatie-gegevens - Altum AI"}
      street={property.bag.housenumber + "" + property.bag.street}
      postCode={property.bag.postcode}
      city={property.city}
      overview={overview}
      reference={reference}
      buildingPhoto={buildingPhoto}
      path="https://docs.altum.ai/apis/locatie-gegevens-api"
      clearResult={clearResults}
      modifyResult={modifyResults}
      result={result}
    />
  );
};

export default LocationDataResult;
