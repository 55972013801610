import React, { FC } from "react";
import Button from "../../../../components/Button";
import { useFormContext } from "./FormContext";

type Props = {
  page: number;
  next: () => void;
  prev: () => void;
};

const PageNav: FC<Props> = ({ page, next, prev }) => {
  const { formValues } = useFormContext();

  return (
    <div className="flex gap-2 self-end">
      <Button
        onClick={() => prev()}
        size="md"
        className={`text-primary bg-white border-2 border-primary h-[40px]`}
        disabled={page === 1}
      >
        <span className="text-primary">Ga terug</span>
      </Button>

      <Button
        className="text-gray-light bg-primary h-[40px]"
        size="md"
        onClick={() => next()}
        disabled={Object.keys(formValues).length === 0}
      >
        Volgende
      </Button>
    </div>
  );
};

export default PageNav;
