import { FormEvent, useEffect, useState } from "react";
import { WozFields } from "./wozfields";
import FormBody from "../FormBody";
import { WOZ } from "../../../assets/images/api/APIimages";
import { postWoz } from "../../../redux/actions/wozActions";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";

const WozForm = () => {
  const [houseOptions, setHouseOptions] = useState<
    FormOptions | FormOptionsSustainability
  >({
    postcode: "",
    housenumber: "",
  });

  const { savedQueries } = useAppSelector((state) => state.woz);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setHouseOptions({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries]);

  const sectionFields = [{ title: "Adres", startIndex: 0, endIndex: 2 }];

  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  const dispatch = useAppDispatch();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions | FormOptionsSustainability = {
      postcode: "",
      housenumber: "",
    };
    houseOptions.postcode = houseOptions.postcode
      .split(" ")
      .join("")
      .toUpperCase();
    Object.entries(houseOptions).forEach(([key, value]) => {
      if (value !== undefined) {
        newFormData[key as keyof (FormOptions | FormOptionsSustainability)] =
          value;
      }
    });
    apiKey && dispatch(postWoz({ formData: newFormData, apiKey }));
  };

  return (
    <FormBody
      title={"WOZ waarde opvragen"}
      desc={
        "De WOZ-API levert WOZ-waarden op voor woningen met BAGID of huisadres. De API geeft in als respons ook andere details, zoals het bouwjaar, coördinaten, doel van eigendom, enzovoort"
      }
      path={"https://docs.altum.ai/apis/woz-api"}
      initialFields={WozFields}
      sectionFields={sectionFields}
      handleSubmit={handleFormSubmit}
      setFormValues={setHouseOptions}
      formValues={houseOptions}
      img={WOZ}
    />
  );
};

export default WozForm;
