import React, {
  useState,
  useEffect,
  FC,
  ChangeEvent,
  FormEventHandler,
  FormEvent,
  ChangeEventHandler,
} from "react";
import styled from "styled-components";
import { Button } from "../styles/styled";
import { FormField, ValidationInput } from "../@types";
import { useValidation } from "../hooks/useValidator";
import { Colors } from "../styles/Theme";

interface FormProps {
  fields: FormField[];
  initInput: any;
  handleSubmit: (formData: any) => void;
  cta: string;
}

export const Form: FC<FormProps> = ({
  fields,
  initInput,
  handleSubmit,
  cta,
}) => {
  const [submitState, setSetSubmitState] = useState({
    submitting: false,
    submitted: false,
  });

  const [userInput, setUserInput] = useState({});
  const { errors } = useValidation();
  const resetState = () =>
    setSetSubmitState({ submitting: false, submitted: false });

  const { submitting, submitted } = submitState;
  const isDone = !submitting && submitted;

  useEffect(() => {
    setTimeout(resetState, 700);
  }, [isDone]);

  useEffect(() => {
    if (!submitting || submitted) return;
    if (Object.keys(userInput).length !== fields.length) return;
    if (!errors) {
      setSetSubmitState({ submitting: true, submitted: true });
    } else {
      setSetSubmitState({ submitting: false, submitted: false });
    }
  }, [errors, userInput, fields, submitting, submitted]);

  const submit: FormEventHandler = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSetSubmitState({ submitting: true, submitted: false });

    if (Object.keys(userInput).length !== Object.keys(initInput).length) {
      return setUserInput({
        ...initInput,
        ...userInput,
      });
    }

    if (Object.keys(errors).length > 0) {
      return;
    }
    handleSubmit(userInput);
    setSetSubmitState({ submitting: false, submitted: true });
  };

  const handleChange: ChangeEventHandler = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <FormStyle
      onSubmit={submit}
      className={`${submitting ? "-submitting" : ""}`}
    >
      <>
        {!isDone &&
          fields.map(({ label: placeholder, type, name }) => (
            <div key={name}>
              <input
                onChange={handleChange}
                {...{ placeholder, type, name }}
                style={{
                  border: `1px solid ${
                    errors && errors[name as keyof ValidationInput]
                      ? "red"
                      : "green"
                  }`,
                }}
              />
              {errors &&
                !submitting &&
                Object.keys(errors).map(
                  (field) =>
                    field === name && (
                      <label
                        key={field}
                        style={{
                          color: "red",
                          overflowWrap: "break-word",
                          inlineSize: "364px",
                        }}
                      >
                        {errors[field]}
                      </label>
                    ),
                )}
            </div>
          ))}

        <div className="submit">
          <Butttonstyle
            size="sm"
            className={`${
              Object.keys(errors).length > 0 ? "btn-disabled" : ""
            }`}
            disabled={Object.keys(errors).length > 0}
          >
            {cta}
          </Butttonstyle>
        </div>
      </>
    </FormStyle>
  );
};

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 10px;
  align-items: center;

  .-submitting {
    opacity: 0.5;
  }

  input {
    width: 364px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    background-color: transparent;
    border-radius: 5px;
    color: ${Colors.main.darkgrey};
    margin: 0 auto 8px;
    font-size: 16px;

    @media (max-width: 960px) {
      width: 283px;
      height: 54.6px;
    }
  }
  .submit {
    min-width: 100%;
    display: flex;
    margin-top: 16px;
  }

  .name {
    display: flex;
  }
`;

const Butttonstyle = styled(Button)`
  width: 364px;
  height: 61px;
  margin: 0 auto 20px;

  @media (max-width: 960px) {
    width: 283px;
    height: 54.6px;
  }
`;
